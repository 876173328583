import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { DockModule } from 'primeng/dock';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CreateWorkspaceComponent } from './create-workspace.component';
import { WorkspaceAgbComponent } from './workspace-agb/workspace-agb.component';
import { WorkspacePaymentResultComponent } from './workspace-payment-result/workspace-payment-result.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    InputTextareaModule,
    FileUploadModule,
    RouterLink,
    RippleModule,
    TranslocoDirective,
    DockModule,
    TabViewModule,
    CalendarModule,
    DividerModule,
    SelectButtonModule,
    ToggleButtonModule,
    FormsModule,
    DropdownModule,
    RadioButtonModule,
    CheckboxModule,
    ImageCropperModule,
  ],
  declarations: [
    CreateWorkspaceComponent,
    WorkspacePaymentResultComponent,
    WorkspaceAgbComponent,
  ],
})
export class WorkspaceBillingModule {}
