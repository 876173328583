<ng-container *transloco="let t">
  <p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel [disabled]="true" [header]="t('create-agb-tab')">
      <sweetpopcorn-workspace-agb (nextPage)="changeTab($event)">
      </sweetpopcorn-workspace-agb>
    </p-tabPanel>
    <p-tabPanel [header]="t('create-rights-tab')" [disabled]="true">
      <div class="flex flex-column w-full p-4">
        <h1>{{ t('workspace-owner-title') }}</h1>

        <div class="flex flex-column w-full gap-2">
          <div class="flex flex-row gap-4">
            <div class="flex align-items-center">
              <p-radioButton
                name="comp"
                [value]="false"
                [(ngModel)]="privateUser"
                inputId="ingredient1"
                id="company"
                (click)="changeValidators()"
              ></p-radioButton>
              <label for="company" class="ml-2">{{ t('company-title') }}</label>
            </div>
            <div class="flex align-items-center">
              <p-radioButton
                name="comp"
                [value]="true"
                [(ngModel)]="privateUser"
                inputId="ingredient2"
                id="private"
                (click)="changeValidators()"
              ></p-radioButton>
              <label for="private" class="ml-2">{{ t('private-title') }}</label>
            </div>
          </div>

          <div *ngIf="privateUser; else companyBlock">
            <form [formGroup]="privateForm">
              <div class="flex-auto p-fluid">
                <div class="flex flex-row justify-content-between gap-4">
                  <div class="w-6">
                    <label for="name" class="block font-medium text-900 mb-2"
                      >{{ t('firstName') }} *
                    </label>
                    <input
                      #firstName
                      id="firstname"
                      type="text"
                      pInputText
                      formControlName="firstName"
                      [maxlength]="70"
                    />
                  </div>
                  <div class="w-6">
                    <label for="name" class="block font-medium text-900 mb-2"
                      >{{ t('lastName') }} *
                    </label>
                    <input
                      #firstName
                      id="lastname"
                      type="text"
                      pInputText
                      formControlName="lastName"
                      [maxlength]="70"
                    />
                  </div>
                  <div class="w-2">
                    <label
                      for="birthdate"
                      class="block font-medium text-900 mb-2"
                      >{{ t('birthdate-label') }} *</label
                    >
                    <p-calendar
                      id="birthDate"
                      dateFormat="dd.mm.yy"
                      formControlName="birthDate"
                    ></p-calendar>
                  </div>
                </div>
                <div class="w-full mt-2">
                  <label for="ownerMail" class="block font-medium text-900 mb-2"
                    >{{ t('contactMail') }} *</label
                  >
                  <input
                    id="ownerMail"
                    type="email"
                    pInputText
                    formControlName="ownerMail"
                  />
                </div>
              </div>
            </form>
          </div>
          <ng-template #companyBlock class="mt-2">
            <form [formGroup]="companyForm">
              <div class="flex-auto p-fluid">
                <div class="flex flex-column gap-2">
                  <div class="w-full">
                    <label
                      for="companyName"
                      class="block font-medium text-900 mb-2"
                      >{{ t('companyName-label') }} *
                    </label>
                    <input
                      #companyName
                      id="companyName"
                      type="text"
                      pInputText
                      formControlName="companyName"
                      [maxlength]="70"
                    />
                  </div>

                  <div class="w-full">
                    <label for="fin" class="block font-medium text-900 mb-2"
                      >{{ t('fin-label') }} *</label
                    >
                    <input
                      id="fin"
                      type="text"
                      pInputText
                      formControlName="fin"
                    />
                  </div>
                  <div class="w-full">
                    <label
                      for="companynameMail"
                      class="block font-medium text-900 mb-2"
                      >{{ t('contactMail') }} *</label
                    >
                    <input
                      id="companynameMail"
                      type="email"
                      pInputText
                      formControlName="ownerMail"
                    />
                  </div>
                </div>
              </div>
            </form>
          </ng-template>
          <div class="flex flex-column justify-content-between w-full gap-2">
            <form [formGroup]="addressForm">
              <div class="w-full">
                <label for="addressrow1" class="block font-medium text-900 mb-2"
                  >{{ t('addressrow-label') }} 1 *</label
                >
                <input
                  id="addressrow1"
                  type="text"
                  pInputText
                  class="w-full"
                  formControlName="addressrow1"
                />
              </div>
              <div class="w-full mt-2">
                <label for="adressrow2" class="block font-medium text-900 mb-2"
                  >{{ t('addressrow-label') }} 2</label
                >
                <input
                  id="adressrow2"
                  type="text"
                  pInputText
                  class="w-full"
                  formControlName="addressrow2"
                />
              </div>
              <div class="w-full flex flex-row gap-4 mt-2">
                <div class="w-full">
                  <label for="plz" class="block font-medium text-900 mb-2"
                    >{{ t('plz-label') }} *</label
                  >
                  <input
                    id="plz"
                    type="number"
                    pInputText
                    class="w-full"
                    formControlName="plz"
                  />
                </div>
                <div class="w-full">
                  <label for="city" class="block font-medium text-900 mb-2"
                    >{{ t('city-label') }} *</label
                  >
                  <input
                    id="city"
                    type="text"
                    pInputText
                    class="w-full"
                    formControlName="city"
                  />
                </div>
                <div class="w-full">
                  <label for="country" class="block font-medium text-900 mb-2"
                    >{{ t('country-label') }} *</label
                  >
                  <p-dropdown
                    id="country"
                    [options]="countries"
                    optionLabel="name"
                    [showClear]="true"
                    placeholder="{{ t('country-label') }}"
                    formControlName="country"
                  ></p-dropdown>
                </div>
              </div>
            </form>
          </div>
          <div>
            <p-button
              pRipple
              label="{{ t('btn-next') }}"
              [disabled]="
                companyForm.invalid ||
                privateForm.invalid ||
                addressForm.invalid
              "
              (click)="activeIndex = 2"
            ></p-button>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel [header]="t('create-workspace-tab')" [disabled]="true">
      <div class="flex flex-column w-full p-4">
        <h1>{{ t('workspace-title') }}</h1>
        <form [formGroup]="form" class="flex flex-column flex-grow-1">
          <div class="flex-auto p-fluid">
            <div class="mb-4">
              <label for="name" class="block font-medium text-900 mb-2"
                >{{ t('workspace-name') }} *
              </label>
              <input
                #workspacename
                id="name"
                type="text"
                pInputText
                formControlName="name"
                [pTooltip]="t('workspace-name-tooltip')"
                tooltipPosition="top"
                [maxlength]="70"
              />
            </div>
            <div class="mb-4">
              <label for="slugName" class="block font-medium text-900 mb-2">{{
                t('slug-name')
              }}</label>
              <input
                id="slugName"
                type="text"
                pInputText
                formControlName="customSlug"
                [placeholder]="workspacename.value"
                [pTooltip]="t('slug-desc')"
                tooltipPosition="top"
              />
            </div>
            <div class="mb-4">
              <label for="visibility" class="block font-medium text-900 mb-2">{{
                t('uploadLimit-label')
              }}</label>
              <p-dropdown
                id="visibility"
                [options]="uoloadLimit"
                optionValue="value"
                formControlName="uploadLimit"
                optionLabel="name"
                [pTooltip]="t('infobox-UploadLimit')"
                tooltipPosition="top"
              ></p-dropdown>
            </div>
            <div class="flex flex-column justify-content-around gap-4 mb-4">
              <div class="w-full">
                <label
                  for="contactUrl"
                  class="block font-medium text-900 mb-2"
                  >{{ t('contactUrl') }}</label
                >
                <input
                  [pTooltip]="'https://www. ...'"
                  tooltipPosition="top"
                  id="contactUrl"
                  type="text"
                  pInputText
                  formControlName="contactUrl"
                />
              </div>
              <div class="w-full">
                <label
                  for="contactPhone"
                  class="block font-medium text-900 mb-2"
                  >{{ t('contactPhone') }}</label
                >
                <input
                  id="contactPhone"
                  type="text"
                  pInputText
                  formControlName="contactPhone"
                />
              </div>
            </div>
            <div class="w-full">
              <label for="password" class="block font-medium text-900 mb-2">{{
                t('workspacePassword')
              }}</label>
              <input
                id="password"
                type="password"
                pInputText
                formControlName="password"
              />
            </div>
          </div>
        </form>
        <div class="flex flex-column mt-4 gap-4">
          <div class="flex flex-column align-items-start mr-1 gap-2">
            <label for="workspaceImageBtn">{{ t('workspaceImage') }} *</label>
            <p-button
              id="workspaceImageBtn"
              label="{{ t('chooseImage') }}"
              icon="pi pi-file-import"
              (click)="fileInput.value = null; fileInput.click()"
            ></p-button>
          </div>
          <input
            #fileInput
            id="image"
            type="file"
            accept="image/*"
            class="hidden"
            (change)="fileChangeEvent($event, 'workspace')"
          />
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="1 / 1"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
          ></image-cropper>
          <div class="flex flex-row justify-content-between gap-4">
            <p-button
              pRipple
              label="{{ t('cancel') }}"
              class="w-auto"
              styleClass="p-button-danger"
              [routerLink]="['/workspaces']"
            ></p-button>
            <p-button
              pRipple
              label="{{ t('btn-back') }}"
              class="w-auto"
              (click)="activeIndex = 1"
            ></p-button>
            <button
              pButton
              pRipple
              label="{{ t('create-workspace') }}"
              class="w-8"
              [loading]="saving"
              [disabled]="!form.valid || saving"
              (click)="createWorkspace()"
              loadingIcon="pi pi-spin pi-spinner"
              iconPos="right"
            ></button>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</ng-container>
