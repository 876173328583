import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SubmissionsService } from '@sweetpopcorn/api-connector';
import { EditorService, NotificationToastService } from '@sweetpopcorn/common';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-submission-form',
  templateUrl: './submission-form.component.html',
  styleUrls: ['./submission-form.component.scss'],
})
export class SubmissionFormComponent {
  form = this.fb.group({
    id: [''],
    password: [''],
    firstName: [''],
    lastName: [''],
    email: [''],
    customerReference: [''],
  });

  constructor(
    private fb: FormBuilder,
    private editorService: EditorService,
    private submissionService: SubmissionsService,
    private notificationToastService: NotificationToastService,
    private router: Router
  ) {}

  saveSubmission() {
    this.submissionService
      .submitSubmission({
        workspaceId: this.editorService.workspace?.id,
        password: this.form.get('password').value,
        firstName: this.form.get('firstName').value,
        lastName: this.form.get('lastName').value,
        email: this.form.get('email').value,
        customerReference: this.form.get('customerReference').value,
      })
      .subscribe(async (submission) => {
        this.notificationToastService.successMessage(
          'Submission created',
          `New submission was created`
        );
        this.form.reset();
        await this.router.navigate(
          [
            '/workspace',
            this.editorService.workspace?.id,
            'submissions',
            submission?.id,
            'upload',
          ],
          { queryParams: { code: submission?.code } }
        );
      });
  }
}
