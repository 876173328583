import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  WorkspaceDto,
  WorkspaceWithBillingInfoDto,
  WorkspacesService,
} from '@sweetpopcorn/api-connector';
import { WorkspaceDetailService } from '@sweetpopcorn/common';
import { StripeService } from 'ngx-stripe';
import { MenuItem } from 'primeng/api';
import { Observable, filter, map, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-workspace',
  templateUrl: './workspace-detail.component.html',
  styleUrls: ['./workspace-detail.component.scss'],
})
export class WorkspaceDetailComponent implements OnInit {
  $workspace: Observable<WorkspaceDto>;
  items: MenuItem[] = [];
  lang = 'de';
  locked = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private workspaceService: WorkspacesService,
    private translocoService: TranslocoService,
    private stripeService: StripeService,
    private router: Router,
    private workspaceDetailService: WorkspaceDetailService
  ) {}

  ngOnInit() {
    const id$ = this.activatedRoute.paramMap.pipe(
      map((paramMap) => paramMap.get('workspaceId')),
      filter((id) => !!id),
      untilDestroyed(this)
    );

    this.workspaceDetailService.initWorkspace(id$);
    this.$workspace = this.workspaceDetailService.workspace$;
    this.$workspace.subscribe((workspace) => {
      this.locked =
        workspace.isLocked || (workspace?.prize?.title ? false : true);
      this.translocoService.langChanges$
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.translocoService.selectTranslate('uploads').subscribe(() => {
            this.setItems();
          });
        });
    });
    this.translocoService.langChanges$.subscribe((value) => {
      this.lang = value;
    });
  }

  setItems() {
    const role = this.workspaceDetailService.userRole;

    this.items = [
      {
        label: this.translocoService.translate('uploads'),
        icon: 'pi pi-fw pi-images',
        routerLink: 'uploads',
      },
      {
        label: this.translocoService.translate('submission'),
        icon: 'pi pi-fw pi-book',
        routerLink: 'submissions',
      },
      {
        label: this.translocoService.translate('challenges'),
        icon: 'pi pi-fw pi-calendar',
        routerLink: 'challenges',
        separator: true,
        visible: !this.locked,
      },
      {
        label: this.translocoService.translate('links'),
        icon: 'pi pi-fw pi-link',
        routerLink: 'links',
        visible: !this.locked,
      },
      {
        label: this.translocoService.translate('users'),
        icon: 'pi pi-fw pi-user',
        routerLink: 'users',
        visible: role < 3 && !this.locked,
      },
      {
        label: this.translocoService.translate('settings'),
        icon: 'pi pi-fw pi-cog',
        routerLink: 'settings',
        visible: role < 3,
      },
    ];
  }

  retryPayment(workspace: WorkspaceWithBillingInfoDto) {
    this.workspaceService
      .recreateWorkspacePaymentSession(workspace.id)
      .pipe(
        switchMap((workspace) => {
          return this.stripeService.redirectToCheckout({
            sessionId: workspace.stripeSessionId,
          });
        })
      )
      .subscribe(async () => {
        await this.router.navigate(['workspace', workspace.slug]);
      });
  }

  navigateSettings() {
    this.router.navigate(['settings'], {
      relativeTo: this.activatedRoute,
      queryParams: { section: 'voucher' },
    });
  }
}
