import { Injectable } from '@angular/core';
import {
  KeycloakEvent,
  KeycloakEventType,
  KeycloakService,
} from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { Observable, filter, switchMap } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  isLoggedIn$: Observable<boolean> = fromPromise(this.keycloak.isLoggedIn());
  userProfile$: Observable<KeycloakProfile> = this.isLoggedIn$.pipe(
    filter((f) => f),
    switchMap(() => fromPromise(this.keycloak.loadUserProfile()))
  );
  constructor(private readonly keycloak: KeycloakService) {
    this.keycloak.keycloakEvents$.subscribe({
      next: (event: KeycloakEvent) => {
        if (event.type === KeycloakEventType.OnTokenExpired) {
          this.keycloak
            .updateToken(50)
            .then(function (refreshed) {
              if (refreshed) {
                console.log('Token refreshed');
                // write any code you required here
              }
            })
            .catch(function () {
              alert('Failed to refresh the token, or the session has expired');
            });
          // this.signOut().then(() => {
          //   console.log('logged out');
          // });
        }
      },
    });
  }

  isLoggedIn() {
    return this.keycloak.isLoggedIn();
  }

  async login() {
    await this.keycloak.login({
      redirectUri: window.location.origin,
    });
  }

  async signup() {
    await this.keycloak.register({
      redirectUri: window.location.origin,
    });
  }
  async signOut() {
    await this.keycloak.logout(window.location.origin);
  }
}
