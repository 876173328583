import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sweetpopcorn-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css'],
})
export class VideoComponent implements OnInit {
  videoPlaying = false;

  @Input() backgroundImageSource = '';
  @Input() videoSrc = '';

  backgroundImage = '';

  ngOnInit(): void {
    this.backgroundImage = this.backgroundImageSource;
  }

  playVideo() {
    this.videoPlaying = true;
    this.backgroundImage = '';
  }

  videoEnded() {
    this.videoPlaying = false;
    this.backgroundImage = this.backgroundImageSource;
  }
}
