import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import { NullWithDefaultPipe } from '@sweetpopcorn/common';
import {
  ActionBarModule,
  UserDialogModule,
  VideoModule,
  VideoOverlayModule,
} from '@sweetpopcorn/ui';
import {
  FormatDistanceToNowPipeModule,
  FormatDistanceToNowPurePipeModule,
  FormatPipeModule,
  ParseIsoPipeModule,
} from 'ngx-date-fns';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxQrcodeStylingModule } from 'ngx-qrcode-styling';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { SkeletonModule } from 'primeng/skeleton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TagModule } from 'primeng/tag';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { WorkspacesOverviewComponent } from '../workspaces-overview/workspaces-overview.component';
import { ChallengeDetailComponent } from './challenge/challenge-detail/challenge-detail.component';
import { ChallengeFormComponent } from './challenge/challenge-form/challenge-form.component';
import { ChallengeOverviewComponent } from './challenge/challenge-overview/challenge-overview.component';
import { LinksComponent } from './links/links.component';
import { SubmissionDetailComponent } from './submission/submission-detail/submission-detail.component';
import { SubmissionFormComponent } from './submission/submission-form/submission-form.component';
import { SubmissionOverviewComponent } from './submission/submission-overview/submission-overview.component';
import { SubmissionUploadComponent } from './submission/submission-upload/submission-upload.component';
import { WorkspaceDetailComponent } from './workspace-detail.component';
import { WorkspaceFormComponent } from './workspace-form/workspace-form.component';
import { WorkspaceOverviewComponent } from './workspace-overview/workspace-overview.component';
import { WorkspaceOwnerComponent } from './workspace-owner/workspace-owner.component';
import { WorkspaceUserListComponent } from './workspace-user-list/workspace-user-list.component';

@NgModule({
  imports: [
    CommonModule,
    TabMenuModule,
    MenuModule,
    TagModule,
    ActionBarModule,
    ButtonModule,
    RippleModule,
    UserDialogModule,
    OverlayPanelModule,
    InputTextModule,
    TableModule,
    FormsModule,
    ConfirmDialogModule,
    DividerModule,
    FileUploadModule,
    TooltipModule,
    AvatarModule,
    AutoCompleteModule,
    DataViewModule,
    DropdownModule,
    InputTextareaModule,
    ReactiveFormsModule,
    RatingModule,
    ImageModule,
    StyleClassModule,
    CheckboxModule,
    ChipModule,
    ParseIsoPipeModule,
    FormatDistanceToNowPipeModule,
    FormatDistanceToNowPurePipeModule,
    FormatPipeModule,
    ToggleButtonModule,
    NullWithDefaultPipe,
    CalendarModule,
    SplitButtonModule,
    NgxQrcodeStylingModule,
    TranslocoDirective,
    VideoOverlayModule,
    VideoModule,
    CardModule,
    SkeletonModule,
    CardModule,
    ImageCropperModule,
  ],
  declarations: [
    WorkspaceOverviewComponent,
    WorkspaceUserListComponent,
    WorkspaceDetailComponent,
    WorkspacesOverviewComponent,
    ChallengeOverviewComponent,
    ChallengeFormComponent,
    WorkspaceFormComponent,
    SubmissionFormComponent,
    SubmissionUploadComponent,
    SubmissionDetailComponent,
    SubmissionOverviewComponent,
    ChallengeDetailComponent,
    LinksComponent,
    WorkspaceOwnerComponent,
  ],
})
export class WorkspaceDetailModule {}
