import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import {
  ChallengeDto,
  ChallengeDtoPaginatedList,
  ChallengesService,
  WorkspaceDto,
  WorkspacesService,
} from '@sweetpopcorn/api-connector';
import { NotificationToastService } from '@sweetpopcorn/common';
import _ from 'lodash';
import { NgxQrcodeStylingComponent, Options } from 'ngx-qrcode-styling';
import { Observable, distinctUntilChanged, map, switchMap, tap } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'sweetpopcorn-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss'],
})
export class LinksComponent implements OnInit {
  challenges$: Observable<ChallengeDtoPaginatedList>;
  workspaceId$: Observable<string>;
  workspace$: Observable<WorkspaceDto>;
  workspace_name = 'workspace';
  workspaceImageUrl: string;
  slugName: string;
  items: ChallengeDto[];
  environment = environment;
  copyLinkConfirmation: { header: string; message: string };
  @ViewChild('qrcode', { static: false })
  public qrcode!: NgxQrcodeStylingComponent;

  public config: Options = {
    dotsOptions: {
      type: 'square',
    },
    backgroundOptions: {
      color: '#ffffff',
    },
    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 5,
    },
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private challengesService: ChallengesService,
    private notificationService: NotificationToastService,
    private translocoService: TranslocoService,
    private workspaceService: WorkspacesService
  ) {}

  ngOnInit() {
    this.workspaceId$ = this.activatedRoute.parent.paramMap.pipe(
      map((paramMap) => paramMap.get('workspaceId')),
      distinctUntilChanged()
    );

    this.challenges$ = this.workspaceId$.pipe(
      switchMap((workspaceId) =>
        this.challengesService.getChallengesFromWorkspace(workspaceId)
      )
    );

    this.challenges$.subscribe((value) => {
      this.items = _.filter(value.items, { isActive: true });
    });

    this.workspace$ = this.workspaceId$.pipe(
      switchMap((workspaceId) =>
        this.workspaceService.getWorkspaceById(workspaceId)
      ),
      tap((workspace) => {
        this.slugName = workspace.slug;
        this.workspaceImageUrl = workspace.file.url;
        this.workspace_name = workspace.name;
      })
    );

    this.workspace$.subscribe();

    this.translocoService.langChanges$.subscribe(() => {
      this.translocoService.selectTranslate('uploads').subscribe(() => {
        this.setMessages();
      });
    });
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.notificationService.successMessage(
      this.copyLinkConfirmation.header,
      this.copyLinkConfirmation.message
    );
  }

  updateQrCode(imageUrl: string, link: string, fileName: string) {
    // console.log(imageUrl);
    // this.qrcode.config.image = imageUrl;
    this.qrcode.config.data = link;
    this.qrcode.update(this.qrcode.config, {}).subscribe(() => {
      setTimeout(() => {
        this.downloadQr(this.qrcode, fileName);
      }, 1000);
    });
  }
  downloadQr(qrcode: NgxQrcodeStylingComponent, fileName: string) {
    qrcode.download(fileName).subscribe();
  }

  setMessages() {
    this.copyLinkConfirmation = {
      header: this.translocoService.translate('copylink-header'),
      message: this.translocoService.translate('copylink-message'),
    };
  }
}
