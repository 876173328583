import { Component, OnInit } from '@angular/core';
import { WorkspaceDto } from '@sweetpopcorn/api-connector';
import {
  AuthorizationService,
  WorkspaceDetailService,
  WorkspaceService,
} from '@sweetpopcorn/common';
import { KeycloakProfile } from 'keycloak-js';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';

@Component({
  selector: 'sweetpopcorn-workspaces-overview',
  templateUrl: './workspaces-overview.component.html',
  styleUrls: ['./workspaces-overview.component.scss'],
})
export class WorkspacesOverviewComponent implements OnInit {
  workspaces$: Observable<WorkspaceDto[]> = this.workspaceService.workspaces$;
  userProfile$: Observable<KeycloakProfile> =
    this.authorizationService.userProfile$;
  items: MenuItem[] = [
    {
      label: 'Actions',
      items: [
        {
          label: 'Add Workspace',
          icon: 'pi pi-times',
        },
      ],
    },
  ];

  constructor(
    private workspaceService: WorkspaceService,
    private authorizationService: AuthorizationService,
    private workspaceDetailService: WorkspaceDetailService
  ) {}

  ngOnInit() {
    this.workspaceDetailService.hideDropDown = true;
  }
}
