<ng-container *transloco="let t">
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <div class="text-blue-600 text-xl font-medium mb-3">
      {{ t('help-page-pre-title') }}
    </div>
    <div class="text-900 text-3xl font-bold mb-3">
      {{ t('help-page-title') }}
    </div>

    <div class="mt-4 mb-2">
      <div class="text-3xl">{{ t('help-workspaces-title') }}</div>
      <div class="text-700">{{ t('help-workspaces-sub-title') }}</div>
    </div>
    <div class="grid">
      <div class="col-12 lg:col-4 p-2">
        <div
          class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex"
        >
          <div class="p-4">
            <div class="flex align-items-center">
              <span
                class="inline-flex border-circle align-items-center justify-content-center bg-primary-200 mr-3"
                style="width: 38px; height: 38px"
              >
                <i class="pi pi-plus-circle text-xl text-black"></i>
              </span>
              <span class="text-900 font-medium text-2xl">{{
                t('help-workspaces-card1-sub-title')
              }}</span>
            </div>
            <div class="text-900 my-3 text-xl font-medium">
              {{ t('help-workspaces-card1-sub-title') }}
            </div>
            <p class="mt-0 mb-3 text-700 line-height-3">
              {{ t('help-workspaces-card1-description') }}
            </p>
          </div>
          <div class="px-4 py-3 surface-100 text-right">
            <button
              pButton
              pRipple
              label="Watch"
              class="p-button-rounded"
              (click)="show('assets/videos/help/placeholder.mp4')"
            ></button>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-4 p-3">
        <div
          class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex"
        >
          <div class="p-4">
            <div class="flex align-items-center">
              <span
                class="inline-flex border-circle align-items-center justify-content-center bg-primary-200 mr-3"
                style="width: 38px; height: 38px"
              >
                <i class="pi pi-globe text-xl text-black"></i>
              </span>
              <span class="text-900 font-medium text-2xl">{{
                t('help-workspaces-card2-title')
              }}</span>
            </div>
            <div class="text-900 my-3 text-xl font-medium">
              {{ t('help-workspaces-card2-sub-title') }}
            </div>
            <p class="mt-0 mb-3 text-700 line-height-3">
              {{ t('help-workspaces-card2-description') }}
            </p>
          </div>
          <div class="px-4 py-3 surface-100 text-right">
            <button
              pButton
              pRipple
              label="Watch"
              class="p-button-rounded"
              (click)="show('assets/videos/help/placeholder.mp4')"
            ></button>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-4 p-3">
        <div
          class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex"
        >
          <div class="p-4">
            <div class="flex align-items-center">
              <span
                class="inline-flex border-circle align-items-center justify-content-center bg-primary-200 mr-3"
                style="width: 38px; height: 38px"
              >
                <i class="pi pi-users text-xl text-black"></i>
              </span>
              <span class="text-900 font-medium text-2xl">{{
                t('help-workspaces-card3-title')
              }}</span>
            </div>
            <div class="text-900 my-3 text-xl font-medium">
              {{ t('help-workspaces-card3-sub-title') }}
            </div>
            <p class="mt-0 mb-3 text-700 line-height-3">
              {{ t('help-workspaces-card3-description') }}
            </p>
          </div>
          <div class="px-4 py-3 surface-100 text-right">
            <button
              pButton
              pRipple
              label="Watch"
              class="p-button-rounded"
              (click)="show('assets/videos/help/placeholder.mp4')"
            ></button>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4 mb-2">
      <div class="text-3xl">{{ t('help-challenges-title') }}</div>
      <div class="text-700">{{ t('help-challenges-sub-title') }}</div>
    </div>
    <div class="grid">
      <div class="col-12 lg:col-4 p-3">
        <div
          class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex"
        >
          <div class="p-4">
            <div class="flex align-items-center">
              <span
                class="inline-flex border-circle align-items-center justify-content-center bg-primary-200 mr-3"
                style="width: 38px; height: 38px"
              >
                <i class="pi pi-verified text-xl text-black"></i>
              </span>
              <span class="text-900 font-medium text-2xl">{{
                t('help-challenges-card1-title')
              }}</span>
            </div>
            <div class="text-900 my-3 text-xl font-medium">
              {{ t('help-challenges-card1-sub-title') }}
            </div>
            <p class="mt-0 mb-3 text-700 line-height-3">
              {{ t('help-challenges-card1-description') }}
            </p>
          </div>
          <div class="px-4 py-3 surface-100 text-right">
            <button
              pButton
              pRipple
              label="Watch"
              class="p-button-rounded"
              (click)="show('assets/videos/help/placeholder.mp4')"
            ></button>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-4 p-3">
        <div
          class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex"
        >
          <div class="p-4">
            <div class="flex align-items-center">
              <span
                class="inline-flex border-circle align-items-center justify-content-center bg-primary-200 mr-3"
                style="width: 38px; height: 38px"
              >
                <i class="pi pi-plus-circle text-xl text-black"></i>
              </span>
              <span class="text-900 font-medium text-2xl">{{
                t('help-challenges-card2-title')
              }}</span>
            </div>
            <div class="text-900 my-3 text-xl font-medium">
              {{ t('help-challenges-card2-sub-title') }}
            </div>
            <p class="mt-0 mb-3 text-700 line-height-3">
              {{ t('help-challenges-card2-description') }}
            </p>
          </div>
          <div class="px-4 py-3 surface-100 text-right">
            <button
              pButton
              pRipple
              label="Watch"
              class="p-button-rounded"
              (click)="show('assets/videos/help/placeholder.mp4')"
            ></button>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-4 p-3">
        <div
          class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex"
        >
          <div class="p-4">
            <div class="flex align-items-center">
              <span
                class="inline-flex border-circle align-items-center justify-content-center bg-primary-200 mr-3"
                style="width: 38px; height: 38px"
              >
                <i class="pi pi-star text-xl text-black"></i>
              </span>
              <span class="text-900 font-medium text-2xl">{{
                t('help-challenges-card3-title')
              }}</span>
            </div>
            <div class="text-900 my-3 text-xl font-medium">
              {{ t('help-challenges-card3-sub-title') }}
            </div>
            <p class="mt-0 mb-3 text-700 line-height-3">
              {{ t('help-challenges-card3-description') }}
            </p>
          </div>
          <div class="px-4 py-3 surface-100 text-right">
            <button
              pButton
              pRipple
              label="Watch"
              class="p-button-rounded"
              (click)="show('assets/videos/help/placeholder.mp4')"
            ></button>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4 mb-2">
      <div class="text-3xl">{{ t('help-general-title') }}</div>
      <div class="text-700">{{ t('help-general-sub-title') }}</div>
    </div>
    <div class="grid">
      <div class="col-12 lg:col-4 p-3">
        <div
          class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex"
        >
          <div class="p-4">
            <div class="flex align-items-center">
              <span
                class="inline-flex border-circle align-items-center justify-content-center bg-primary-200 mr-3"
                style="width: 38px; height: 38px"
              >
                <i class="pi pi-credit-card text-xl text-black"></i>
              </span>
              <span class="text-900 font-medium text-2xl">{{
                t('help-general-card1-title')
              }}</span>
            </div>
            <div class="text-900 my-3 text-xl font-medium">
              {{ t('help-general-card1-sub-title') }}
            </div>
            <p class="mt-0 mb-3 text-700 line-height-3">
              {{ t('help-general-card1-description') }}
            </p>
          </div>
          <div class="px-4 py-3 surface-100 text-right">
            <button
              pButton
              pRipple
              label="Watch"
              class="p-button-rounded"
              (click)="show('assets/videos/help/placeholder.mp4')"
            ></button>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-4 p-3">
        <div
          class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex"
        >
          <div class="p-4">
            <div class="flex align-items-center">
              <span
                class="inline-flex border-circle align-items-center justify-content-center bg-primary-200 mr-3"
                style="width: 38px; height: 38px"
              >
                <i class="pi pi-comments text-xl text-black"></i>
              </span>
              <span class="text-900 font-medium text-2xl">{{
                t('help-general-card2-title')
              }}</span>
            </div>
            <div class="text-900 my-3 text-xl font-medium">
              {{ t('help-general-card2-sub-title') }}
            </div>
            <p class="mt-0 mb-3 text-700 line-height-3">
              {{ t('help-general-card2-description') }}
            </p>
          </div>
          <div class="px-4 py-3 surface-100 text-right">
            <button
              pButton
              pRipple
              label="Watch"
              class="p-button-rounded"
              (click)="show('assets/videos/help/placeholder.mp4')"
            ></button>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-4 p-3">
        <div
          class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex"
        >
          <div class="p-4">
            <div class="flex align-items-center">
              <span
                class="inline-flex border-circle align-items-center justify-content-center bg-primary-200 mr-3"
                style="width: 38px; height: 38px"
              >
                <i class="pi pi-sun text-xl text-black"></i>
              </span>
              <span class="text-900 font-medium text-2xl">{{
                t('help-general-card3-title')
              }}</span>
            </div>
            <div class="text-900 my-3 text-xl font-medium">
              {{ t('help-general-card3-sub-title') }}
            </div>
            <p class="mt-0 mb-3 text-700 line-height-3">
              {{ t('help-general-card3-description') }}
            </p>
          </div>
          <div class="px-4 py-3 surface-100 text-right">
            <button
              pButton
              pRipple
              label="Watch"
              class="p-button-rounded"
              (click)="show('assets/videos/help/placeholder.mp4')"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
