import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ChallengeDto,
  ChallengesService,
  FileDto,
  FileService,
  Orientation,
  UploadLimit,
  Visibility,
} from '@sweetpopcorn/api-connector';
import {
  NotificationToastService,
  WorkspaceDetailService,
  dateValidator,
} from '@sweetpopcorn/common';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable, filter, map, merge, switchMap, toArray } from 'rxjs';
import { environment } from '../../../../environments/environment';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-guide-form',
  templateUrl: './challenge-form.component.html',
  styleUrls: ['./challenge-form.component.scss'],
  providers: [NotificationToastService],
})
export class ChallengeFormComponent implements OnInit {
  challenge$: Observable<ChallengeDto>;
  basePath = environment.basePath;
  challengeImageChangedEvent: any = '';
  voucherImageChangedEvent: any = '';
  isScheduled = true;
  createSuccess = { title: '', message: '' };
  createFail = { title: '', message: '' };
  // img: Blob;
  challengeForm = this.fb.group(
    {
      id: [null],
      title: ['', Validators.required],
      titleEn: [''],
      description: ['', Validators.required],
      descriptionEn: [''],
      visibility: [Visibility.Public, Validators.required],
      workspaceSlugOrId: [''],
      isPhotoAllowed: [true],
      isVideoAllowed: [true],
      isAudioAllowed: [true],
      isTextAllowed: [true],
      minTextLength: [0],
      maxTextLength: [1000],
      minAudioVideoDuration: [],
      maxAudioVideoDuration: [],
      photoVideoOrientation: [Orientation.Vertical],
      maxFileSize: [1024 * 10, Validators.required],
      maxSubmissionSize: [15, Validators.required],
      uploadLimit: [UploadLimit.NoLimit],
      submissionStartTime: [
        new Date(new Date().setDate(new Date().getDate() + 1)),
      ],
      submissionEndTime: [
        new Date(new Date().setMonth(new Date().getMonth() + 1)),
      ],
      imgUrl: ['', Validators.required],
      image: [null],
      imgId: [''],
    },
    { validators: dateValidator }
  );

  voucherForm = this.fb.group({
    id: [''],
    prizeTitle: ['', Validators.required],
    prizeTitleEn: [''],
    prizeDescription: [''],
    prizeDescriptionEn: [''],
    prizeUrl: [''],
    image: [null],
    imageId: [''],
    imageUrl: ['', Validators.required],
  });

  workspaceId: string;
  maxDescriptionLength = 1000;
  uoloadLimit = [];
  orientation = [];
  saving = false;
  voucherSaving = false;

  constructor(
    private fb: FormBuilder,
    private challengesService: ChallengesService,
    private notificationToastService: NotificationToastService,
    private activatedRoute: ActivatedRoute,
    private translocoService: TranslocoService,
    private router: Router,
    private http: HttpClient,
    private fileService: FileService,
    private workspaceDetailService: WorkspaceDetailService
  ) {}

  ngOnInit() {
    this.workspaceDetailService.workspace$.subscribe((value) => {
      this.workspaceId = value.id;
    });

    this.challenge$ = this.activatedRoute.paramMap.pipe(
      map((paramMap) => paramMap.get('challengeId')),
      filter((id) => !!id),
      switchMap((challengeId) =>
        this.challengesService.getChallengeById(challengeId)
      )
    );

    this.challenge$.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data.id) {
        this.isScheduled = data.isScheduled;
        this.disableFormValues();
      }
      this.challengeForm.patchValue({
        id: data?.id,
        workspaceSlugOrId: data?.workspaceId,
        title: data?.title,
        titleEn: data?.titleEn,
        description: data?.description,
        descriptionEn: data?.descriptionEn,
        visibility: data?.visibility ?? Visibility.Public,
        uploadLimit: data?.uploadLimit ?? UploadLimit.NoLimit,
        isPhotoAllowed: data?.isPhotoAllowed,
        isVideoAllowed: data?.isVideoAllowed,
        isAudioAllowed: data?.isAudioAllowed,
        isTextAllowed: data?.isTextAllowed,
        minTextLength: data?.minTextLength,
        maxTextLength: data?.maxTextLength,
        minAudioVideoDuration: data?.minAudioVideoDuration,
        maxAudioVideoDuration: data?.maxAudioVideoDuration,
        photoVideoOrientation: data?.photoVideoOrientation,
        maxFileSize: data?.maxFileSize,
        maxSubmissionSize: data?.maxSubmissionSize,
        imgUrl: data?.file?.url,
        submissionStartTime: new Date(data?.submissionStartTime),
        submissionEndTime: new Date(data?.submissionEndTime),
        imgId: data?.file?.id,
        //     : new Date(),
      });
      this.voucherForm.patchValue({
        id: data?.id,
        prizeTitle: data?.prize?.title,
        prizeTitleEn: data?.prize?.titleEn,
        prizeDescription: data?.prize?.description,
        prizeDescriptionEn: data?.prize?.descriptionEn,
        prizeUrl: data?.prize?.url,
        imageUrl: data?.prize?.image?.url,
        imageId: data?.prize?.image?.id,
      });
    });
    this.translocoService.langChanges$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.setItems());
  }

  uploadChallengeImage(): Observable<FileDto> {
    const challengeData = this.challengeForm.getRawValue();

    const file = new File([challengeData.image], 'challengeImage', {
      type: challengeData.image.type,
    });

    return this.fileService.apiFileUploadWorkspaceIdPost(this.workspaceId, [
      file,
    ]);
  }

  uploadVoucherImage(): Observable<FileDto> {
    const formData = new FormData();
    const voucherData = this.voucherForm.getRawValue();

    formData.append('file', voucherData.image);
    const file = new File([voucherData.image], 'prizeImage', {
      type: voucherData.image.type,
    });

    return this.fileService.apiFileUploadWorkspaceIdPost(this.workspaceId, [
      file,
    ]);
  }

  uploadChallenge() {
    const formData = this.challengeForm.getRawValue();
    const voucherData = this.voucherForm.getRawValue();
    if (!formData.id) {
      return this.challengesService.createChallenge(this.workspaceId, {
        title: formData.title,
        titleEn: formData?.titleEn,
        description: formData?.description,
        descriptionEn: formData?.descriptionEn,
        visibility: formData?.visibility,
        uploadLimit: formData?.uploadLimit,
        isPhotoAllowed: formData?.isPhotoAllowed,
        isVideoAllowed: formData?.isVideoAllowed,
        isAudioAllowed: formData?.isAudioAllowed,
        isTextAllowed: formData?.isTextAllowed,
        minTextLength: formData?.minTextLength,
        maxTextLength: formData?.maxTextLength,
        minAudioVideoDuration: formData?.minAudioVideoDuration,
        maxAudioVideoDuration: formData?.maxAudioVideoDuration,
        photoVideoOrientation: formData?.photoVideoOrientation,
        maxFileSize: formData?.maxFileSize,
        maxSubmissionSize: formData?.maxSubmissionSize,
        imageId: formData?.imgId,
        submissionStartTime: formData.submissionStartTime
          ? new Date(formData?.submissionStartTime).toISOString()
          : '',
        submissionEndTime: formData.submissionEndTime
          ? new Date(formData?.submissionEndTime).toISOString()
          : '',
        winningPrizeTitle: voucherData?.prizeTitle,
        winningPrizeTitleEn: voucherData?.prizeTitleEn,
        winningPrizeDescription: 'empty',
        winningPrizeDescriptionEn: 'empty',
        winningPrizeUrl: voucherData?.prizeUrl,
        winningPrizeImageId: voucherData?.imageId,
      });
    } else {
      return this.challengesService.updateChallenge(formData.id, {
        title: formData.title,
        titleEn: formData?.titleEn,
        description: formData?.description,
        descriptionEn: formData?.descriptionEn,
        visibility: formData?.visibility,
        isPhotoAllowed: formData?.isPhotoAllowed,
        isVideoAllowed: formData?.isVideoAllowed,
        isAudioAllowed: formData?.isAudioAllowed,
        isTextAllowed: formData?.isTextAllowed,
        uploadLimit: formData?.uploadLimit,
        minTextLength: formData?.minTextLength,
        maxTextLength: formData?.maxTextLength,
        minAudioVideoDuration: formData?.minAudioVideoDuration,
        maxAudioVideoDuration: formData?.maxAudioVideoDuration,
        photoVideoOrientation: formData?.photoVideoOrientation,
        maxFileSize: formData?.maxFileSize,
        maxSubmissionSize: formData?.maxSubmissionSize,
        imageId: formData?.imgId,
        submissionStartTime: formData.submissionStartTime
          ? new Date(formData?.submissionStartTime).toISOString()
          : '',
        submissionEndTime: formData.submissionEndTime
          ? new Date(formData?.submissionEndTime).toISOString()
          : '',
        winningPrizeTitle: voucherData?.prizeTitle,
        winningPrizeTitleEn: voucherData?.prizeTitleEn,
        winningPrizeDescription: 'empty',
        winningPrizeDescriptionEn: 'empty',
        winningPrizeUrl: voucherData?.prizeUrl,
        winningPrizeImageId: voucherData?.imageId,
      });
    }
  }

  saveChallenge() {
    this.saving = true;
    const observables = [];

    observables.push(this.uploadChallengeImage());
    observables.push(this.uploadVoucherImage());
    merge(...observables)
      .pipe(
        toArray(),
        switchMap((results: FileDto[]) => {
          for (const r of results) {
            if (r?.fileName === 'challengeImage') {
              this.challengeForm.patchValue({
                imgId: r?.id,
                imgUrl: r?.url,
              });
            } else if (r?.fileName === 'prizeImage') {
              this.voucherForm.patchValue({
                imageId: r?.id,
                imageUrl: r?.url,
              });
            }
          }
          return this.uploadChallenge();
        })
      )
      .subscribe({
        next: async (challenge: ChallengeDto) => {
          this.notificationToastService.successMessage(
            this.createSuccess.title,
            this.createSuccess.message + ' ' + `${challenge?.title}`
          );
          this.saving = false;
          this.router.navigate(['/workspace', this.workspaceId, 'challenges']);
        },
        error: (error) => {
          this.notificationToastService.errorMessage(
            this.createFail.title,
            this.createFail.message + ' ' + `${error}`
          );
        },
      });
  }

  private setItems() {
    this.createSuccess = {
      title: this.translocoService.translate('info-created'),
      message: this.translocoService.translate('info-createdDetail'),
    };
    this.createFail = {
      title: this.translocoService.translate('info-createdFailed'),
      message: this.translocoService.translate('info-createdFailedDetail'),
    };
    this.uoloadLimit = [
      {
        name: this.translocoService.translate('upload-nolimit'),
        value: UploadLimit.NoLimit,
      },
      {
        name: this.translocoService.translate('upload-once'),
        value: UploadLimit.Once,
      },
      {
        name: this.translocoService.translate('upload-daily'),
        value: UploadLimit.Daily,
      },
      {
        name: this.translocoService.translate('upload-weekly'),
        value: UploadLimit.Weekly,
      },
      {
        name: this.translocoService.translate('upload-monthly'),
        value: UploadLimit.Monthly,
      },
      {
        name: this.translocoService.translate('upload-yearly'),
        value: UploadLimit.Yearly,
      },
    ];
  }

  challengeFileChangeEvent(event: any): void {
    this.challengeImageChangedEvent = event;
  }
  challengeImageCropped(event: ImageCroppedEvent) {
    // this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);

    this.challengeForm.patchValue({
      image: event.blob,
      imgUrl: event.objectUrl,
    });
    // event.blob can be used to upload the cropped image
  }

  voucherFileChangeEvent(event: any): void {
    this.voucherImageChangedEvent = event;
  }
  voucherImageCropped(event: ImageCroppedEvent) {
    // this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);

    this.voucherForm.patchValue({
      image: event.blob,
      imageUrl: event.objectUrl,
    });
    // event.blob can be used to upload the cropped image
  }

  disableFormValues() {
    if (!this.isScheduled) {
      this.challengeForm.disable();
      this.voucherForm.disable();
    }
  }
}
