<ng-container *transloco="let t">
  <div class="text-900 font-semibold text-lg mt-3">{{ t('profile') }}</div>
  <p-divider></p-divider>
  <form [formGroup]="form">
    <div class="flex gap-5 flex-column-reverse md:flex-row">
      <div class="flex-auto p-fluid">
        <div class="mb-4">
          <label for="username" class="block font-normal text-900 mb-2">{{
            t('username')
          }}</label>
          <input
            id="username"
            type="text"
            pInputText
            formControlName="username"
          />
        </div>
        <div class="mb-4">
          <label for="firstname" class="block font-normal text-900 mb-2">{{
            t('firstName')
          }}</label>
          <input
            id="firstname"
            type="text"
            pInputText
            formControlName="firstname"
          />
        </div>
        <div class="mb-4">
          <label for="lastname" class="block font-normal text-900 mb-2">{{
            t('lastName')
          }}</label>
          <input
            id="lastname"
            type="text"
            pInputText
            formControlName="lastname"
          />
        </div>
        <div class="mb-4">
          <label for="email" class="block font-normal text-900 mb-2"
            >Email
          </label>
          <input id="email" type="email" pInputText formControlName="email" />
        </div>
        <div>
          <div class="flex flex-row align-items-center">
            <p>{{ t('profile-text') }}</p>
            <a
              class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700"
              href="mailto:support@sweetpopcorn.studio"
            >
              <i class="pi pi-send text-xl"></i>
            </a>
          </div>

          <button
            pButton
            pRipple
            label="{{ t('profile-btn') }}"
            class="w-auto mt-3"
            [disabled]="true"
          ></button>
        </div>
      </div>
    </div>
  </form>
</ng-container>
