<ng-container *transloco="let t">
  <p-confirmDialog
    #cd
    [header]="t('confirmation')"
    icon="pi pi-exclamation-triangle"
  >
    <ng-template pTemplate="footer">
      <button
        type="button"
        pButton
        icon="pi pi-times"
        label="{{ t('btn-no') }}"
        (click)="cd.reject()"
      ></button>
      <button
        type="button"
        pButton
        icon="pi pi-check"
        label="{{ t('btn-yes') }}"
        (click)="cd.accept()"
      ></button>
    </ng-template>
  </p-confirmDialog>
  <div class="px-0 py-4 md:px-4 surface-card shadow-2 border-round mt-4">
    <p-dataView
      [value]="items$ | async"
      [paginator]="true"
      [lazy]="true"
      [rows]="9"
      filterBy="name"
      layout="list"
      (onLazyLoad)="loadData($event)"
      [totalRecords]="totalCount"
      [emptyMessage]="t('dataViewer-emptyMessage')"
    >
      <ng-template pTemplate="header">
        <div class="flex justify-content-between">
          <h1 class="m-0 text-900 text-3xl font-medium line-height-3 mb-3">
            {{ t('submission') }}
          </h1>
          <div class="flex-grow-1"></div>
        </div>
        <div>
          {{ t('submission-desc') }}
        </div>
      </ng-template>
      <ng-template pTemplate="listItem" let-submission>
        <div
          class="flex w-full align-items-center justify-content-between my-1 p-2 surface-border border-1"
        >
          <div
            class="flex flex-column flex-grow-1 cursor-pointer"
            [routerLink]="[submission?.id]"
          >
            <div class="flex flex-row mb-1 align-items-center">
              <div class="text-900 font-bold">
                {{ submission?.firstName }} {{ submission?.lastName }}
              </div>
              <div *ngIf="submission?.customerReference" class="text-900">
                {{ submission?.customerReference }}
              </div>
            </div>
            <div class="grid">
              <div class="col">
                {{ submission?.email }}
              </div>
              <div class="col">
                <span
                  [pTooltip]="
                    submission?.createdOn
                      | dfnsParseIso
                      | dfnsFormat : 'dd.MM.yyyy HH:mm'
                  "
                  tooltipPosition="bottom"
                >
                  {{
                    submission?.createdOn
                      | dfnsParseIso
                      | dfnsFormatDistanceToNowPure
                  }}
                  ago
                </span>
              </div>
            </div>
            <div class="flex flex-row gap-1">
              <p-tag
                *ngIf="submission?.challengeId"
                value="{{ t('challenge') }}"
              ></p-tag>
              <p-tag
                *ngIf="submission?.isWinningSubmission"
                severity="success"
                value="{{ t('winner') }}"
              ></p-tag>
            </div>
          </div>
          <div class="flex flex-row align-items-center">
            <i class="pi pi-images mr-1"></i>
            <div class="">{{ submission?.itemCount }}</div>
          </div>
          <div class="flex flex-row align-items-center flex-nowrap h-full ml-4">
            <button
              pButton
              [routerLink]="[submission?.id]"
              class="p-button-text p-button-plain mr-1 h-full"
              icon="pi pi-eye"
            ></button>
            <button
              *ngIf="userRole < 4"
              pButton
              (click)="deleteSubmission(submission)"
              class="p-button-text p-button-plain mr-1 h-full p-button-danger"
              icon="pi pi-trash"
            ></button>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </div>
</ng-container>
