<ng-container *transloco="let t">
  <div class="surface-section px-4 py-6 md:px-6">
    <div class="text-2xl text-900 font-bold mb-2 text-center">
      {{ t('faq-title') }}
    </div>
    <div class="text-700 line-height-3 mb-6 text-center">
      {{ t('faq-description') }}
    </div>
    <p-accordion styleClass="mb-6" [activeIndex]="0">
      <p-accordionTab [header]="t(faq.i18nQuestion)" *ngFor="let faq of faqs">
        <div class="line-height-3" [innerHTML]="t(faq.i18nAnswer)"></div>
      </p-accordionTab>
    </p-accordion>
    <div
      class="border-1 border-round surface-border p-5 surface-50 flex flex-column align-items-center"
    >
      <p-avatarGroup styleClass="text-center">
        <p-avatar
          image="assets/images/people/alex.jpg"
          shape="square"
          size="large"
        ></p-avatar>
        <p-avatar
          image="assets/images/people/simon.jpg"
          shape="square"
          size="large"
        ></p-avatar>
        <p-avatar
          image="assets/images/people/clemens.jpg"
          shape="square"
          size="large"
        ></p-avatar>
        <p-avatar
          image="assets/images/people/marlies.jpg"
          shape="square"
          size="large"
        ></p-avatar>
        <p-avatar
          image="assets/images/people/michi.jpg"
          shape="square"
          size="large"
        ></p-avatar>
      </p-avatarGroup>
      <div class="font-medium text-xl mt-5 mb-3">
        {{ t('faq-contact-us-title') }}
      </div>
      <p class="text-700 line-height-3 mt-0 mb-5">
        {{ t('faq-contact-us-description') }}
      </p>
      <a
        href="mailto:office@sweetpopcorn.studio"
        target="_blank"
        rel="noopener noreferrer"
        class="p-button font-bold w-auto mt-3"
        >{{ t('faq-contact-us-button') }}</a
      >
    </div>
  </div>
</ng-container>
