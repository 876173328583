<div class="bg-dialog video">
  <div
    class="p-16 px-4 sm:px-16 basis-1/3 h-full pane"
    [ngStyle]="{ background: 'url(' + backgroundImageSource + ')' }"
  >
    <ng-container *ngIf="videoPlaying">
      <video
        class="w-full"
        controls
        #videoPlayer
        controlsList="nodownload"
        autoplay
        (ended)="videoEnded()"
        style="
          right: 0;
          bottom: 0;
          min-width: 100%;
          min-height: 20%;
          height: 100%;
          width: 100%;
          z-index: 9500;
        "
      >
        <source src="{{ videoSrc }}" type="video/mp4" />
        Your browser does not support the video.
      </video>
    </ng-container>

    <div
      class="flex w-full h-full justify-content-center align-items-center"
      *ngIf="!videoPlaying"
    >
      <!-- Arrow-->

      <div class="place-self-center">
        <img
          src="assets/images/play_button.svg"
          class="w-full filter-white"
          (click)="playVideo()"
        />
      </div>
    </div>
  </div>
</div>
