<ng-container *transloco="let t">
  <p-confirmDialog #cd header="Confirmation" icon="pi pi-exclamation-triangle">
    <ng-template pTemplate="footer">
      <button
        type="button"
        pButton
        icon="pi pi-times"
        label="{{ t('btn-no') }}"
        (click)="cd.reject()"
      ></button>
      <button
        type="button"
        pButton
        icon="pi pi-check"
        label="{{ t('btn-yes') }}"
        (click)="cd.accept()"
      ></button>
    </ng-template>
  </p-confirmDialog>

  <div class="px-0 py-4 md:px-4 surface-card shadow-2 border-round mt-4">
    <p-dataView
      [value]="items$ | async"
      [paginator]="true"
      [lazy]="true"
      [rows]="nextPageSize"
      filterBy="name"
      layout="grid"
      (onLazyLoad)="loadData($event)"
      [totalRecords]="totalCount"
      [emptyMessage]="t('dataViewer-emptyMessage')"
    >
      <ng-template pTemplate="header">
        <div class="flex flex-column md:flex-row gap-2">
          <div class="flex justify-content-center align-items-center mr-2">
            {{ t('filter-label') }}:
          </div>
          <p-toggleButton
            [(ngModel)]="checked"
            onLabel="=="
            offLabel=">="
            (onChange)="changeSort()"
          ></p-toggleButton>
          <p-dropdown
            [options]="sortOptions"
            [(ngModel)]="filterValue"
            [placeholder]="starPlaceholder"
            (onChange)="onSortChange($event)"
            styleClass="mb-2 md:mb-0"
          ></p-dropdown>
        </div>
        <div class="mt-3">
          {{ t('gallery-desc') }}
        </div>
      </ng-template>

      <ng-template let-item pTemplate="gridItem">
        <div class="col-12 sm:col-6 lg:col-4 xl:col-3">
          <div
            class="m-1 p-1 border-1 surface-border surface-card border-round"
          >
            <div class="flex flex-column align-items-center overflow-hidden">
              <sweetpopcorn-video
                class="mb-1"
                *ngIf="item?.contentType.includes('video')"
                [backgroundImageSource]="item?.thumbnail?.url"
                [videoSrc]="item?.file?.url"
              ></sweetpopcorn-video>
              <p-image
                *ngIf="item?.contentType.includes('image')"
                [previewImageSrc]="item?.file?.url"
                [imageStyle]="{
                  'max-width': '250px',
                  'max-height': '250px',
                  'object-fit': 'cover'
                }"
                [src]="item?.thumbnail?.url"
                alt="Image"
                width="250"
                height="250"
                [preview]="true"
              >
              </p-image>
            </div>
            <div class="flex flex-row justify-content-center p-2">
              <div class="flex align-items-center">
                <p-rating
                  [(ngModel)]="item.rating"
                  (ngModelChange)="ratingChanged(item, $event)"
                ></p-rating>
              </div>

              <div>
                <p-splitButton
                  styleClass="p-button-text customSplit mb-1"
                  #ua
                  icon="pi pi-cloud-download"
                  (onClick)="downloadImage(item)"
                  (onDropdownClick)="selectContent(item)"
                  [model]="imageOptions"
                ></p-splitButton>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </div>
</ng-container>
