import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'sweetpopcorn-video-overlay',
  templateUrl: './video-overlay.component.html',
  styleUrls: ['./video-overlay.component.scss'],
})
export class VideoOverlayComponent {
  videoUrl = '';

  constructor(public config: DynamicDialogConfig) {
    this.videoUrl = this.config?.data?.videoUrl;
  }
}
