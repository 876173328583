<div class="flex flex-row gap-4">
  <span class="p-input-icon-left p-input-filled flex-grow-1">
    <i class="pi pi-search"></i>
    <input
      pInputText
      type="text"
      class="w-full"
      [placeholder]="placeholderText"
      [(ngModel)]="searchText"
      (ngModelChange)="searchOnChange($event)"
    />
  </span>
  <p-button
    [label]="actionButton?.text"
    [routerLink]="actionButton.routerLink"
    class="flex-grow-0"
    *ngIf="actionButton"
  ></p-button>
</div>
