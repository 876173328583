import { Injectable } from '@angular/core';
import { WorkspaceDto, WorkspacesService } from '@sweetpopcorn/api-connector';
import {
  Observable,
  Subject,
  combineLatest,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceDetailService {
  private refreshWorkspacesSubject = new Subject<void>();
  workspace$: Observable<WorkspaceDto>;
  hideDropDown = false;
  userRole: number;
  roles = { Owner: 1, Admin: 2, Editor: 3, Viewer: 4 };
  constructor(private workspaceService: WorkspacesService) {}

  initWorkspace(id: Observable<string>) {
    this.workspace$ = combineLatest([
      id,
      this.refreshWorkspacesSubject.asObservable().pipe(startWith(null)),
    ]).pipe(
      map(([workspaceId, _]) => workspaceId),
      switchMap((worksoaceId) =>
        this.workspaceService.getWorkspaceById(worksoaceId)
      ),
      tap((workspace) => {
        this.userRole = this.roles[workspace.currentUserRole];
      })
    );
    this.hideDropDown = false;
  }

  reloadWorkspace() {
    this.refreshWorkspacesSubject.next();
  }
}
