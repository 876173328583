import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ChallengesService,
  SubmissionItemDto,
  SubmissionsService,
} from '@sweetpopcorn/api-connector';
import {
  NotificationToastService,
  WorkspaceDetailService,
} from '@sweetpopcorn/common';
import {
  ConfirmationService,
  LazyLoadEvent,
  MenuItem,
  SelectItem,
} from 'primeng/api';
import {
  Observable,
  Subject,
  combineLatest,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-workspace-overview',
  templateUrl: './workspace-overview.component.html',
  styleUrls: ['./workspace-overview.component.scss'],
  providers: [ConfirmationService, NotificationToastService],
})
export class WorkspaceOverviewComponent implements OnInit {
  items$: Observable<SubmissionItemDto[]>;
  reloadSubj = new Subject<void>();

  nextPage = 1;
  nextPageSize = 12;
  hasMoreData = true;
  totalCount = 0;

  sortOptions!: SelectItem[];
  starValueExact = null;
  starValueMin = null;
  checked = false;
  filterValue: SelectItem;
  starPlaceholder = '';
  userRole: number;

  imageOptions: MenuItem[];
  selectedContent: SubmissionItemDto;
  downloadConfirmation: { header: string; message: string };
  DeleteConfirmation: { header: string; message: string; notification: string };

  constructor(
    private activatedRoute: ActivatedRoute,
    private submissionsService: SubmissionsService,
    private confirmationService: ConfirmationService,
    private notificationToastService: NotificationToastService,
    private challengesService: ChallengesService,
    private router: Router,
    private translocoService: TranslocoService,
    private workspaceDetailService: WorkspaceDetailService
  ) {}

  ngOnInit() {
    this.items$ = combineLatest([
      this.activatedRoute.parent.paramMap.pipe(
        map((paramMap) => paramMap.get('workspaceId')),
        filter((workspaceId) => !!workspaceId)
      ),
      this.reloadSubj.asObservable(),
    ]).pipe(
      map(([workspaceId, _]) => workspaceId),
      switchMap((workspaceId) =>
        this.submissionsService.getSubmissionItemsOfWorkspace(
          workspaceId,
          this.starValueMin,
          this.starValueExact,
          this.nextPage,
          this.nextPageSize
        )
      ),
      tap((data) => {
        this.hasMoreData = data.hasNextPage;
        this.totalCount = data.totalCount;
      }),
      map((r) => r.items)
    );

    this.translocoService.langChanges$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.translocoService.selectTranslate('star').subscribe(() => {
          this.setRatingItems();
        });
      });
  }

  loadData(event: LazyLoadEvent) {
    this.nextPage = event.first / event.rows + 1;
    this.reloadSubj.next();
  }

  async downloadImage(item: SubmissionItemDto) {
    const submission$ = this.submissionsService
      .getSubmissionById(item.submissionId)
      .pipe(untilDestroyed(this));
    const isChallengeActive$ = submission$.pipe(
      filter((sub) => !!sub.challengeId),
      switchMap((sub) =>
        this.challengesService.getChallengeById(sub.challengeId)
      ),
      map((challenge) => challenge.isActive),
      untilDestroyed(this)
    );

    submission$.subscribe((value) => {
      if (value.challengeId) {
        isChallengeActive$.subscribe((active) => {
          if (active) {
            this.confirmationService.confirm({
              message: this.downloadConfirmation.message,
              header: this.downloadConfirmation.header,
              icon: 'pi pi-info-circle',
              accept: () => {
                this.download(item);
              },
            });
          } else {
            this.download(item);
          }
        });
      } else {
        this.download(item);
      }
    });
  }

  download(item: SubmissionItemDto) {
    const link = document.createElement('a');
    link.href = item?.file?.url;
    link.download = 'image file name here';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  ratingChanged(item: SubmissionItemDto, value: number) {
    if (!value) {
      value = 0;
    }
    this.submissionsService
      .rateSubmissionItem(item?.id, { rating: value })
      .subscribe();
  }

  onSortChange(event: any) {
    const value = event.value;
    if (this.checked) {
      this.starValueExact = value;
      this.starValueMin = null;
    } else {
      this.starValueExact = null;
      this.starValueMin = value;
    }

    this.reloadSubj.next();
  }

  changeSort() {
    if (this.filterValue) {
      this.onSortChange({ value: this.filterValue });
    }
  }

  selectContent(item: SubmissionItemDto) {
    this.selectedContent = item;
  }

  deleteConfirmation(item: SubmissionItemDto) {
    this.confirmationService.confirm({
      message: this.DeleteConfirmation.message,
      header: this.DeleteConfirmation.header,
      icon: 'pi pi-info-circle',
      accept: () => {
        this.submissionsService.deleteSubmissionItem(item.id).subscribe(() => {
          this.notificationToastService.infoMessage(
            this.DeleteConfirmation.notification,
            item.id
          );
          this.reloadSubj.next();
        });
      },
    });
  }

  navigateToSubmission(item: SubmissionItemDto) {
    this.router.navigate(['..', 'submissions', item.submissionId], {
      relativeTo: this.activatedRoute,
    });
  }

  setRatingItems() {
    this.userRole = this.workspaceDetailService.userRole;
    this.imageOptions = [
      {
        icon: 'pi pi-info-circle',
        label: this.translocoService.translate('submission'),
        command: () => {
          this.navigateToSubmission(this.selectedContent);
        },
      },
      {
        visible: this.userRole < 4,
        icon: 'pi pi-trash',
        label: this.translocoService.translate('deleteWorkspaceBtn'),
        command: (event) => {
          this.deleteConfirmation(this.selectedContent);
        },
      },
    ];
    this.starPlaceholder = this.translocoService.translate('starRaiting');
    this.sortOptions = [
      { label: this.translocoService.translate('noRating'), value: null },
      { label: '5 ' + this.translocoService.translate('star'), value: 5 },
      { label: '4 ' + this.translocoService.translate('star'), value: 4 },
      { label: '3 ' + this.translocoService.translate('star'), value: 3 },
      { label: '2 ' + this.translocoService.translate('star'), value: 2 },
      { label: '1 ' + this.translocoService.translate('star'), value: 1 },
      { label: '0 ' + this.translocoService.translate('star'), value: 0 },
    ];
    this.downloadConfirmation = {
      header: this.translocoService.translate('downloadConfirmation-header'),
      message: this.translocoService.translate('downloadConfirmation-message'),
    };

    this.DeleteConfirmation = {
      header: this.translocoService.translate('deleteItemConfirmation-header'),
      message: this.translocoService.translate(
        'deleteItemConfirmation-message'
      ),
      notification: this.translocoService.translate('message-item-deleted'),
    };
  }
}
