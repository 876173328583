<ng-container *transloco="let t">
  <div class="surface-section px-4 py-6 md:px-6 m-4 flex flex-column gap-4">
    <h1 class="text-900 font-bold mb-2 text-center">
      {{ t('gtc-title') }}
    </h1>

    <div class="flex flex-column p-8 gap-4">
      <div>
        <span class="text-3xl">Präambel</span>
        <p>
          Die vorliegende Vereinbarung definiert die Rahmenbedingungen für die
          Geschäftsbeziehung zwischen der SweetPopcorn.Studio GmbH, FN 568758i,
          Seespitzstraße 8, 5700 Zell am See, nachfolgend "Anbieter" genannt,
          und ihren geschäftlichen Kunden, nachfolgend "Kunde" genannt,
          bezüglich der Nutzung von PiViDro, einer innovativen Softwarelösung.
        </p>
      </div>

      <div>
        <span class="text-3xl">Allgemeines und Geltungsbereich</span>
        <p>
          PiViDro richtet sich ausschließlich an Unternehmer, die PiViDro im
          Rahmen ihrer gewerblichen oder selbständigen beruflichen Tätigkeit
          nutzen. Verbraucher sind von der Nutzung der Software ausgeschlossen.
          Durch die Registrierung bei und die Nutzung von PiViDro erklärt sich
          der Kunde mit den hier aufgeführten Allgemeinen Geschäftsbedingungen
          (AGB) einverstanden und verpflichtet sich, diese zu befolgen. Diese
          Allgemeinen Geschäftsbedingungen gelten für alle Verträge und
          Geschäftsbeziehungen zwischen dem Anbieter und ihren Kunden bezüglich
          der Nutzung der Softwarelösung PiViDro. Die AGB regeln die
          Bereitstellung und Nutzung von PiViDro, einschließlich aller
          Funktionen, Updates und Upgrades, die dem Kunden zur Verfügung
          gestellt werden. Der Geltungsbereich erstreckt sich auf alle Dienste,
          die im Zusammenhang mit PiViDro angeboten werden, einschließlich
          technischem Support, Beratungsdienstleistungen und jeglichen
          Zusatzleistungen, die der Anbieter gegenüber dem Kunden erbringt.
          Durch die Nutzung von PiViDro oder durch die Zustimmung zu diesen AGB
          im Rahmen des Registrierungsprozesses oder bei der Inanspruchnahme von
          Dienstleistungen des Anbieters erklärt sich der Kunde mit diesen
          Bedingungen einverstanden und akzeptiert ihre Geltung als verbindlich
          für die gesamte Geschäftsbeziehung. Abweichende, entgegenstehende oder
          ergänzende Allgemeine Geschäftsbedingungen des Kunden werden nicht
          Vertragsbestandteil, es sei denn, ihre Geltung wird ausdrücklich
          schriftlich durch den Anbieter zugestimmt.
        </p>
      </div>

      <div>
        <span class="text-3xl">Vertragsabschluss und -dauer</span>
        <p>
          Der Vertrag zwischen dem Anbieter und dem Kunden kommt mit der
          erfolgreichen Registrierung des Kunden für PiViDro und der
          ausdrücklichen Akzeptanz dieser Allgemeinen Geschäftsbedingungen (AGB)
          durch den Kunden zustande. Die Registrierung erfordert die Angabe
          aller notwendigen Informationen durch den Kunden und die Bestätigung
          der Registrierung durch den Anbieter. Die Dauer dieses Vertrags ist
          unbefristet und beginnt mit dem Datum der Freischaltung des Zugangs zu
          PiViDro für den Kunden durch den Anbieter.
        </p>
      </div>

      <div>
        <span class="text-3xl">Leistungsumfang</span>
        <p>
          Der Zugang zu PiViDro und dessen Funktionalitäten erfolgt online über
          eine vom Anbieter bereitgestellte Plattform. Der Kunde erhält für die
          Dauer des Vertrags einen nicht-exklusiven, nicht übertragbaren Zugang
          zu PiViDro, der ausschließlich für gewerbliche oder berufliche Zwecke
          genutzt werden darf. Der Anbieter gewährleistet eine angemessene
          Verfügbarkeit der Online-Plattform, behält sich jedoch Wartungszeiten
          vor, während derer PiViDro möglicherweise nicht zugänglich ist. Über
          geplante Wartungsarbeiten wird der Kunde, soweit möglich, im Voraus
          informiert.<br /><br />
          Der Anbieter verpflichtet sich, regelmäßige Updates und Verbesserungen
          von PiViDro durchzuführen, um die Software aktuell zu halten und das
          Nutzererlebnis kontinuierlich zu verbessern. Der Kunde hat keinen
          Anspruch auf spezifische Erweiterungen oder Anpassungen der Software,
          es sei denn, diese wurden individuell vereinbart. Sonderleistungen,
          die über den Standardumfang von PiViDro hinausgehen, wie etwa
          individuelle Anpassungen oder spezielle Schulungen, können gegen eine
          zusätzliche Vergütung nach Absprache mit dem Anbieter erbracht werden.
          Nutzungsbedingungen
          <br /><br />
          Der Kunde verpflichtet sich zur wahrheitsgemäßen und vollständigen
          Angabe seiner persönlichen und geschäftlichen Daten. Benutzername und
          Passwort (“Login“) dürfen nur von einer Person verwendet werden. Der
          Kunde ist verpflichtet, die Zugangsdaten zu seinem Account vor der
          unbefugten Kenntnisnahme Dritter zu schützen. Der Anbieter besitzt das
          geistige Eigentum an allen schutzfähigen Komponenten des angebotenen
          Dienstes, einschließlich des Namens, der Komponenten der
          Benutzeroberfläche, des grafischen Designs, der Funktionalität sowie
          der Dokumentation. Das Kopieren, Verändern, Anpassen, Vervielfältigen,
          Dekompilieren oder Disassemblieren von Teilen des Dienstes ist
          ausdrücklich untersagt. Soweit während der Vertragslaufzeit neue
          Versionen, Updates, Upgrades oder sonstige Änderungen an der Software
          vorgenommen werden, gelten die vorstehenden Rechte auch für diese. Der
          Anbieter gewährleistet eine Verfügbarkeit des Dienstes von 95% im
          Jahresmittel. Von der Gewährleistung ausgeschlossen sind
          Leistungsmängel, die nicht vom Anbieter zu vertreten sind.
        </p>
      </div>

      <div>
        <span class="text-3xl">Vergütung und Zahlungsbedingungen</span>
        <p>
          Für die Nutzung von PiViDro verpflichtet sich der Kunde zur Zahlung
          einer Vergütung, deren Höhe sich nach dem jeweils gewählten Tarif und
          den gebuchten Zusatzleistungen richtet. Alle Tarife und Preise für
          Zusatzleistungen sind vorab transparent auf der Website des Anbieters
          oder in einem gesonderten Angebot aufgeführt. Die angegebenen Preise
          verstehen sich, sofern nicht anders angegeben, zuzüglich der jeweils
          gültigen Umsatzsteuer (derzeit 20%). Der Anbieter behält sich vor, die
          Preise jederzeit abzuändern. Hierbei wird der Kunde mittels einer
          Benachrichtigung oder durch Angabe auf der Website mit einer
          angemessenen vor Inkrafttreten informiert. Von dieser Änderung bleiben
          bereits bezahlte Monate unberührt.<br /><br />
          Die Zahlung der Vergütung erfolgt im Voraus für den gebuchten
          Nutzungszeitraum. Die Bezahlung kann über die vom Anbieter
          akzeptierten Zahlungsmittel, wie z.B. Debitkarte oder Kreditkarte oder
          SEPA-Abbuchung geleistet werden. Der Kunde verpflichtet sich, für eine
          ausreichende Deckung des angegebenen Zahlungsmittels zu sorgen und die
          für die Zahlungsabwicklung notwendigen Informationen korrekt und
          aktuell zu halten. Rechnungen werden dem Kunden elektronisch zur
          Verfügung gestellt und enthalten eine detaillierte Aufstellung der
          erbrachten Leistungen und der dafür anfallenden Kosten. Bei
          Zahlungsverzug ist der Anbieter berechtigt, Verzugszinsen in
          gesetzlich zulässiger Höhe zu erheben und den Zugang zu PiViDro zu
          sperren, bis der ausstehende Betrag vollständig beglichen ist.<br /><br />
          Sollten zusätzliche Kosten durch Rückbuchungen oder ähnliche Vorgänge
          entstehen, die auf ein Verschulden des Kunden zurückzuführen sind,
          werden diese Kosten dem Kunden in Rechnung gestellt. Eventuelle
          Änderungen der Zahlungsbedingungen werden dem Kunden mit einer
          angemessenen Frist vor Inkrafttreten mitgeteilt.
        </p>
      </div>
      <div>
        <span class="text-3xl">Datenschutz und Datensicherheit</span>
        <p>
          Der Anbieter verpflichtet sich, bei der Verarbeitung personenbezogener
          Daten die Bestimmungen der DSGVO und des Datenschutzgesetzes
          einzuhalten.<br /><br />
          Der Kunde wird hiermit darüber informiert, dass seine Daten, wie Name,
          Kontaktdaten und Zahlungsinformationen, erforderlich sind, um den
          Zugang zu PiViDro zu ermöglichen und die entsprechenden
          Dienstleistungen zu erbringen. Der Anbieter trifft angemessene
          technische und organisatorische Maßnahmen, um die Sicherheit und den
          Schutz der verarbeiteten Daten zu gewährleisten, insbesondere gegen
          unbefugten Zugriff, Verlust oder Zerstörung. Der Kunde hat das Recht,
          Auskunft über die zu seiner Person gespeicherten Daten zu erhalten,
          deren Berichtigung, Löschung oder Einschränkung der Verarbeitung zu
          verlangen sowie der Verarbeitung zu widersprechen. Zudem hat der Kunde
          das Recht auf Datenübertragbarkeit gemäß den gesetzlichen
          Bestimmungen. Anfragen diesbezüglich sind schriftlich an den
          Datenschutzbeauftragten des Anbieters zu richten. Für die Verarbeitung
          personenbezogener Daten, die über die unmittelbare
          Vertragsdurchführung hinausgeht, wird die ausdrückliche Zustimmung des
          Kunden eingeholt. Der Kunde kann seine Einwilligung jederzeit mit
          Wirkung für die Zukunft widerrufen. Der Widerruf der Einwilligung
          berührt nicht die Rechtmäßigkeit der aufgrund der Einwilligung bis zum
          Widerruf erfolgten Verarbeitung.
        </p>
      </div>

      <div>
        <span class="text-3xl">Kündigung</span>
        <p>
          Der Kunde hat das Recht, den Vertrag über die Nutzung von PiViDro
          jederzeit mit einer Frist von 30 Tagen zum Ende des gebuchten
          Zeitraums zu kündigen. Die Kündigung muss schriftlich oder über das
          Kundenportal erfolgen. Der Anbieter behält sich darüber hinaus das
          Recht vor, den Vertrag aus wichtigem Grund fristlos zu kündigen. Ein
          wichtiger Grund liegt insbesondere vor, wenn der Kunde gegen
          wesentliche Vertragspflichten verstößt und diesen Verstoß auch nach
          Abmahnung nicht innerhalb einer angemessenen Frist behebt oder wenn
          der Kunde mit der Zahlung der vereinbarten Vergütung in Verzug gerät
          und den ausstehenden Betrag nicht innerhalb einer gesetzten Nachfrist
          begleicht.<br /><br />
          Im Falle einer Kündigung durch den Kunden vor Ablauf eines gebuchten
          Zeitraums besteht kein Anspruch auf Rückerstattung bereits geleisteter
          Zahlungen für den laufenden Buchungszeitraum. Im Falle einer
          fristlosen Kündigung durch den Anbieter aus wichtigem Grund hat der
          Kunde ebenfalls keinen Anspruch auf Rückerstattung. Das Konto des
          Kunden wird zunächst bis zum Abschluss eines neuen Vertrages auf
          "read-only" gesetzt. Nach Ablauf von 30 Tagen wird der Account
          deaktiviert. Ein Login ist dann nicht mehr möglich und die Daten
          werden unwiderruflich gelöscht.
        </p>
      </div>
      <div>
        <span class="text-3xl">Haftungsbeschränkungen</span>
        <p>
          Die Haftung des Anbieters für Schäden, die aus der Nutzung oder der
          Unmöglichkeit der Nutzung von PiViDro resultieren, ist auf Vorsatz und
          grobe Fahrlässigkeit beschränkt. Für Datenverluste und entgangenen
          Gewinn haftet der Anbieter nur, soweit solche Schäden bei Anwendung
          üblicher Sorgfalt durch regelmäßige und risikoadäquate
          Datensicherungsmaßnahmen des Kunden vermeidbar gewesen wären. Die
          Haftung für Schäden, die aufgrund von Sicherheitslücken oder Fehlern
          entstehen, die trotz Einhaltung der üblichen Sorgfalt nicht vermieden
          werden konnten, ist ausgeschlossen. Die Haftung für indirekte Schäden,
          insbesondere für entgangenen Gewinn, ist, soweit gesetzlich zulässig,
          ausgeschlossen. Dies gilt nicht, soweit eine Haftung zwingend
          vorgeschrieben ist, beispielsweise nach dem Produkthaftungsgesetz oder
          in Fällen des Vorsatzes, der groben Fahrlässigkeit, bei Körperschäden
          oder wegen der Verletzung des Lebens.<br /><br />
          Mit PiViDro werden Mustertexte (zB Teilnahmebedingungen etc:) zur
          Verfügung gestellt. Alle Angaben in unseren Mustertexten
          (Teilnahmebedingungen etc.) erfolgen unverbindlich. Sie bedürfen einer
          sorgfältigen Anpassung durch den Kunden auf die konkrete
          Marketingaktion und können eine individuelle Rechtsberatung nicht
          ersetzen. Der Kunde ist für die rechtliche (insbesondere urheber-,
          datenschutz-, IT-, kennzeichen- und lauterkeitsrechtliche) Überprüfung
          und damit die rechtliche Absicherung seiner Werbemaßnahmen
          verantwortlich. Die platzierten Muster-Texte des Anbieters stellen
          lediglich eine generelle Information dar, sodass dafür keine wie immer
          geartete Haftung oder Gewährleistung übernommen werden kann.<br /><br />
          Die vorstehenden Haftungsbeschränkungen gelten auch für die
          persönliche Haftung der Angestellten, Vertreter und Organe des
          Anbieters sowie für Ansprüche gegen den Anbieter aufgrund unerlaubter
          Handlungen.
        </p>
      </div>
      <div>
        <span class="text-3xl">Änderungen der AGB</span>
        <p>
          Beabsichtigte Änderungen dieser AGB oder des Vertrages werden dem
          Vertragspartner wie nachstehend geregelt angeboten (in weiterer Folge
          „Änderungsangebot“). Dabei werden die vom Änderungsangebot betroffenen
          Bestimmungen der AGB oder des Vertrages und die dazu vorgeschlagenen
          Änderungen in einer Gegenüberstellung (in weiterer Folge
          „Gegenüberstellung“) dargestellt. Betrifft das Änderungsangebot die
          AGB, wird die vollständige Fassung der neuen AGB auch auf ihrer
          Webseite veröffentlicht werden.<br /><br />
          Das Änderungsangebot und die Gegenüberstellung werden dem
          Vertragspartner per E-Mail an die bekannt gegebene E-Mail-Adresse
          zugestellt. Das Änderungsangebot samt Gegenüberstellung muss dem
          Vertragspartner jedenfalls spätestens zwei Monate vor dem
          beabsichtigten Zeitpunkt des Inkrafttretens der Änderungen zugehen.
          Die Zustimmung des Vertragspartners zum Änderungsangebot gilt als
          erteilt, wenn beim Anbieter vor dem beabsichtigten Zeitpunkt des
          Inkrafttretens der angebotenen Änderungen kein Widerspruch des
          Vertragspartners einlangt. Darauf wird der Vertragspartner im
          Änderungsangebot hingewiesen.<br /><br />
          Im Falle einer beabsichtigten Änderung der AGB oder des Vertrages hat
          der Vertragspartner das Recht, den Vertrag vor dem Inkrafttreten der
          Änderung kostenlos und fristlos zu kündigen. Darauf wird der
          Vertragspartner im Änderungsangebot hingewiesen.<br /><br />
          Sonstige Bestimmungen, Gerichtsstand und Rechtswahl Jede Änderung
          dieses Vertrags bedarf der Schriftform; dies gilt auch für ein Abgehen
          vom Schriftformerfordernis. Mündliche Nebenabreden bestehen nicht.<br /><br />
          Sollte eine der Bestimmungen dieser AGB unwirksam sein, so tritt
          anstelle dieser Bestimmung eine wirksame Bestimmung, die im Falle von
          Verbrauchern gesetzlich vorgesehen ist, im Falle von Unternehmern gilt
          eine der ursprünglichen Bestimmung am nächsten kommende als
          vereinbart. Die übrigen Bestimmungen der AGB bleiben wirksam. Auf
          diesen Vertrag findet, unter Ausschluss von Kollisionsnormen,
          ausschließlich österreichisches Recht Anwendung. Für sämtliche
          Streitigkeiten aus oder im Zusammenhang mit diesem Vertrag vereinbaren
          die Parteien die ausschließliche Zuständigkeit des sachlich
          zuständigen Gerichtes in Zell am See.
        </p>
      </div>
    </div>

    <div
      class="border-1 border-round surface-border p-5 surface-50 flex flex-column align-items-center"
    >
      <div class="font-medium text-xl mt-5 mb-3">
        {{ t('faq-contact-us-title') }}
      </div>
      <a
        href="mailto:office@sweetpopcorn.studio"
        target="_blank"
        rel="noopener noreferrer"
        class="p-button font-bold w-auto mt-3"
        >{{ t('faq-contact-us-button') }}</a
      >
    </div>
  </div>
</ng-container>
