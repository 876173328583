import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { NotificationToastComponent } from './notification-toast.component';

@NgModule({
  imports: [CommonModule, ToastModule],
  declarations: [NotificationToastComponent],
  exports: [NotificationToastComponent],
  providers: [MessageService],
})
export class NotificationToastModule {}
