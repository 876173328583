<ng-container *transloco="let t">
  <p-confirmDialog
    #cd
    [header]="t('confirmation')"
    icon="pi pi-exclamation-triangle"
  >
    <ng-template pTemplate="footer">
      <button
        type="button"
        pButton
        icon="pi pi-times"
        label="{{ t('btn-no') }}"
        (click)="cd.reject()"
      ></button>
      <button
        type="button"
        pButton
        icon="pi pi-check"
        label="{{ t('btn-yes') }}"
        (click)="cd.accept()"
      ></button>
    </ng-template>
  </p-confirmDialog>
  <div class="px-0 py-4 md:px-4 surface-card shadow-2 border-round mt-4">
    <p-dataView
      #dv
      [value]="challenge$ | async"
      layout="list"
      [paginator]="true"
      [lazy]="true"
      [rows]="nextPageSize"
      filterBy="name"
      (onLazyLoad)="loadData($event)"
      [totalRecords]="totalCount"
      [emptyMessage]="t('dataViewer-emptyMessage')"
    >
      <ng-template pTemplate="header">
        <div class="flex justify-content-between">
          <h1 class="m-0 text-900 text-3xl font-medium line-height-3 mb-3">
            {{ t('challenges') }}
          </h1>
          <div class="flex-grow-1"></div>
          <p-button
            *ngIf="mightEdit"
            label="{{ t('create-challenge') }}"
            [disabled]="!canEdit"
            [routerLink]="['new']"
            icon="pi pi-plus"
            class="flex-grow-0"
          ></p-button>
        </div>
        <div>
          {{ t('challenges-desc') }}
        </div>
      </ng-template>
      <ng-template let-challenge pTemplate="listItem">
        <div
          class="flex w-full align-items-center justify-content-between my-1 p-2 surface-border border-1"
        >
          <div
            class="flex flex-column flex-grow-1 cursor-pointer"
            [routerLink]="[challenge?.id]"
          >
            <div class="flex mb-1 align-items-center">
              <p-tag
                *ngIf="challenge?.isActive"
                value="{{ t('status-active') }}"
                severity="success"
                class="mr-1"
              ></p-tag>
              <p-tag
                *ngIf="challenge?.isScheduled"
                value="{{ t('status-scheduled') }}"
                severity="info"
                class="mr-1"
              ></p-tag>
              <p-tag
                *ngIf="challenge?.hasWinningSubmission"
                value="{{ t('status-finished') }}"
                severity="success"
                class="mr-1"
              ></p-tag>
              <p-tag
                *ngIf="challenge?.isClosed"
                value="{{ t('status-closed') }}"
                severity="danger"
                class="mr-1"
              ></p-tag>
              <p-tag
                *ngIf="challenge?.prize?.image?.id"
                value="{{ t('status-active-voucher') }}"
                class="mr-1"
              ></p-tag>
            </div>
            <div class="flex flex-row mt-1">
              <p-avatar
                image="{{ challenge?.file?.url }}"
                styleClass="mr-2 bg-white"
                size="xlarge"
                shape="circle"
              ></p-avatar>
              <div class="flex flex-column">
                <div class="block text-xl font-bold ml-1">
                  {{ challenge?.title }}
                </div>
                <p class="text-overflow-ellipsis white-space-normal">
                  {{
                    challenge?.description?.substring(0, 50) | nullWithDefault
                  }}
                </p>
              </div>
            </div>
            <div class="text-600 font-medium">
              <span>
                {{
                  challenge?.submissionStartTime
                    | dfnsParseIso
                    | dfnsFormat : 'dd.MM.yyyy HH:mm'
                }}
                |
                {{
                  challenge?.submissionEndTime
                    | dfnsParseIso
                    | dfnsFormat : 'dd.MM.yyyy HH:mm'
                }}
              </span>
            </div>
            <div class="flex flex-row mt-2 w-full">
              <p-tag
                *ngIf="challenge?.isPhotoAllowed"
                value="{{ t('photo') }}"
                class="mr-1"
              ></p-tag>
              <p-tag
                *ngIf="challenge?.isVideoAllowed"
                value="{{ t('video') }}"
                class="mr-1"
              ></p-tag>
            </div>
          </div>
          <div class="flex flex-row align-items-center">
            <i class="pi pi-book mr-1"></i>
            <div class="mr-2">{{ challenge?.submissionCount }}</div>
            <i class="pi pi-images mr-1"></i>
            <div class="">{{ challenge?.submissionItemCount }}</div>
          </div>
          <div class="flex flex-row align-items-center flex-nowrap h-full ml-4">
            <button
              pButton
              [routerLink]="[challenge?.id]"
              class="p-button-text p-button-plain mr-1 h-full tex"
              icon="pi pi-eye"
            ></button>
            <button
              *ngIf="!challenge?.isClosed"
              pButton
              (click)="copyChallengeLink(challenge.id)"
              class="p-button-text p-button-plain mr-1 h-full tex"
              icon="pi pi-link"
            ></button>
            <button
              *ngIf="userRole !== 'Viewer'"
              pButton
              [routerLink]="[challenge?.id, 'edit']"
              class="p-button-text p-button-plain mr-1 h-full"
              icon="pi pi-cog"
            ></button>
            <button
              *ngIf="userRole !== 'Viewer'"
              pButton
              (click)="deleteChallenge(challenge)"
              class="p-button-text p-button-plain mr-1 h-full p-button-danger"
              icon="pi pi-trash"
            ></button>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </div>
</ng-container>
