import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ChallengeDto,
  ChallengesService,
  WorkspaceDto,
  WorkspaceRole,
  WorkspacesService,
} from '@sweetpopcorn/api-connector';
import { NotificationToastService } from '@sweetpopcorn/common';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import {
  Observable,
  Subject,
  combineLatest,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs';
import { environment } from '../../../../environments/environment';
@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-challenge-overview',
  templateUrl: './challenge-overview.component.html',
  styleUrls: ['./challenge-overview.component.scss'],
  providers: [ConfirmationService, NotificationToastService],
})
export class ChallengeOverviewComponent implements OnInit {
  workspace$: Observable<WorkspaceDto>;
  challenge$: Observable<ChallengeDto[]>;
  reloadSubj = new Subject<void>();
  environment = environment;
  copyLinkConfirmation: { header: string; message: string };
  slugname = '';
  nextPage = 1;
  nextPageSize = 12;
  hasMoreData = true;
  totalCount = 0;
  userRole: string;

  mightEdit = false;
  canEdit = false;

  constructor(
    private challengesService: ChallengesService,
    private workspacesService: WorkspacesService,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private notificationToastService: NotificationToastService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.workspace$ = this.activatedRoute.parent.parent.paramMap.pipe(
      map((paramMap) => paramMap.get('workspaceId')),
      filter((id) => !!id),
      switchMap((workspaceId) =>
        this.workspacesService.getWorkspaceById(workspaceId)
      ),
      tap((workspace) => {
        this.mightEdit = this.IsAtLeastEditor(workspace?.currentUserRole);
        this.canEdit = !workspace?.isLocked;
        this.userRole = workspace.currentUserRole;
      })
    );
    this.workspace$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.slugname = value.slug;
    });

    this.challenge$ = combineLatest([
      this.workspace$,
      this.reloadSubj.asObservable(),
    ]).pipe(
      map(([workspace, _]) => workspace),
      switchMap((workspace) =>
        this.challengesService.getChallengesFromWorkspace(
          workspace?.id,
          this.nextPage,
          this.nextPageSize
        )
      ),
      tap((data) => {
        this.hasMoreData = data.hasNextPage;
        this.totalCount = data.totalCount;
      }),
      map((data) => data.items)
    );

    this.translocoService.langChanges$.subscribe(() => {
      this.translocoService.selectTranslate('uploads').subscribe(() => {
        this.setMessages();
      });
    });
  }

  loadData(event: LazyLoadEvent) {
    this.nextPage = event.first / event.rows + 1;
    this.reloadSubj.next();
  }

  private IsAtLeastEditor(role: WorkspaceRole): boolean {
    return (
      role === WorkspaceRole.Editor ||
      role === WorkspaceRole.Owner ||
      role === WorkspaceRole.Admin
    );
  }
  copyChallengeLink(challengeId: string) {
    const challengeUrl =
      this.environment.uploadUrl + this.slugname + '/challenge/' + challengeId;
    navigator.clipboard.writeText(challengeUrl).then(
      () => {
        this.notificationToastService.successMessage(
          this.copyLinkConfirmation.header,
          this.copyLinkConfirmation.message
        );
      },
      (err) => {
        console.error('Failed to copy: ', err);
      }
    );
  }

  deleteChallenge(challenge: ChallengeDto) {
    this.confirmationService.confirm({
      message: this.translocoService.translate('deleteChallenge-btn') + '?',
      accept: () => {
        this.challengesService.deleteChallenge(challenge?.id).subscribe({
          next: () => {
            this.reloadSubj.next();
            this.notificationToastService.successMessage(
              this.translocoService.translate('challenge'),
              this.translocoService.translate('deleteChallenge-message')
            );
          },
          error: (error) => {
            this.notificationToastService.errorMessage(
              this.translocoService.translate('info-challengeDeletedFailed'),
              JSON.stringify(error)
            );
          },
        });
      },
    });
  }
  setMessages() {
    this.copyLinkConfirmation = {
      header: this.translocoService.translate('copylink-header'),
      message: this.translocoService.translate('copylink-message'),
    };
  }
}
