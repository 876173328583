<ng-container *transloco="let t">
  <p-confirmDialog
    #cd
    header="{{ t('confirmation') }}"
    message="{{ t('confirmation-delete-workspace') }}"
    icon="pi pi-exclamation-triangle"
  >
    <ng-template pTemplate="footer">
      <button
        type="button"
        pButton
        icon="pi pi-times"
        label="{{ t('btn-no') }}"
        (click)="cd.reject()"
      ></button>
      <button
        type="button"
        pButton
        icon="pi pi-check"
        label="{{ t('btn-yes') }}"
        (click)="cd.accept()"
      ></button>
    </ng-template>
  </p-confirmDialog>

  <div class="mb-4">
    <div class="px-0 py-4 md:px-4 surface-card shadow-2 border-round mt-4">
      <div class="flex flex-column w-full p-2">
        <form [formGroup]="form" class="flex flex-column flex-grow-1">
          <h1 class="m-0 text-900 text-3xl font-medium line-height-3 mb-3">
            {{ t('workspace-settings') }}
          </h1>

          <div class="flex-auto p-fluid">
            <div class="mb-4">
              <label for="name" class="block font-medium text-900 mb-2"
                >{{ t('workspace-name') }} *</label
              >
              <input
                id="name"
                type="text"
                pInputText
                formControlName="name"
                [maxlength]="70"
              />
            </div>
            <div class="mb-4">
              <label for="visibility" class="block font-medium text-900 mb-2">{{
                t('uploadLimit-label')
              }}</label>
              <p-dropdown
                id="visibility"
                [options]="uoloadLimit"
                optionValue="value"
                formControlName="uploadLimit"
                optionLabel="name"
              ></p-dropdown>
            </div>
            <div class="mb-4">
              <label for="contactUrl" class="block font-medium text-900 mb-2">{{
                t('contactUrl')
              }}</label>
              <input
                [pTooltip]="'https://www. ...'"
                tooltipPosition="top"
                id="contactUrl"
                type="text"
                pInputText
                formControlName="contactUrl"
              />
            </div>
            <div class="mb-4">
              <label for="contactMail" class="block font-medium text-900 mb-2"
                >{{ t('contactMail') }} *</label
              >
              <input
                id="contactMail"
                type="text"
                pInputText
                formControlName="contactMail"
              />
            </div>
            <div class="mb-4">
              <label
                for="contactPhone"
                class="block font-medium text-900 mb-2"
                >{{ t('contactPhone') }}</label
              >
              <input
                id="contactPhone"
                type="text"
                pInputText
                formControlName="contactPhone"
              />
            </div>
            <div class="mb-4">
              <label for="password" class="block font-medium text-900 mb-2">{{
                t('workspacePassword')
              }}</label>
              <div class="flex flex-row gap-2">
                <input
                  id="password"
                  type="password"
                  pInputText
                  formControlName="password"
                />
                <p-button
                  *ngIf="form?.get('hasPassword')?.value"
                  styleClass="p-button-danger"
                  icon="pi pi-trash"
                  (click)="deletePassword()"
                ></p-button>
              </div>
            </div>
            <div class="mb-4">
              <label for="image" class="block font-medium text-900 mb-2">{{
                t('workspaceImage')
              }}</label>
              <div class="flex flex-row">
                <img
                  *ngIf="form?.get('imageUrl')?.value"
                  height="100px"
                  [src]="form?.get('imageUrl')?.value"
                  class="border-round mr-3"
                />
                <div class="flex flex-row">
                  <div class="flex align-items-center mr-1">
                    <p-button
                      label="{{ t('chooseImage') }} *"
                      icon="pi pi-file-import"
                      (click)="fileInput.value = null; fileInput.click()"
                    ></p-button>
                  </div>
                </div>
              </div>
              <input
                #fileInput
                id="image"
                type="file"
                accept="image/*"
                class="hidden"
                (change)="fileChangeEvent($event, 'workspace')"
              />
              <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [aspectRatio]="1 / 1"
                format="png"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"
              ></image-cropper>
            </div>
          </div>
        </form>

        <div class="flex flex-row w-full">
          <div class="flex-grow-1"></div>
          <button
            pButton
            pRipple
            [loading]="saving"
            label="{{ t('saveChanges') }}"
            icon="pi pi-save"
            [disabled]="!canEdit || this.form.invalid"
            (click)="saveChanges()"
            loadingIcon="pi pi-spin pi-spinner"
            iconPos="right"
          ></button>
        </div>
      </div>
    </div>

    <div
      class="px-0 py-4 md:px-4 surface-card shadow-2 border-round mt-4"
      id="main_voucher"
    >
      <h1 class="m-0 text-900 text-3xl font-medium line-height-3">
        {{ t('defaultVoucher') }}
      </h1>
      <span>{{ t('defaultVoucher-Desc') }}</span>

      <form [formGroup]="voucherForm" class="flex flex-column flex-grow-1 mt-4">
        <div class="flex-auto p-fluid">
          <div class="mb-4">
            <label for="voucherTitle" class="block font-medium text-900 mb-2"
              >{{ t('voucherPrize') }} [{{ t('lang-de') }}] *</label
            >
            <input
              id="voucherTitle"
              type="text"
              pInputText
              formControlName="prizeTitle"
              [pTooltip]="t('desc-prize-title')"
              tooltipPosition="top"
              [maxlength]="70"
            />
          </div>
          <div class="mb-4">
            <label for="voucherTitleEN" class="block font-medium text-900 mb-2"
              >{{ t('voucherPrize') }} [{{ t('lang-en') }}] *</label
            >
            <input
              id="voucherTitleEN"
              type="text"
              pInputText
              [pTooltip]="t('desc-prize-title-en')"
              tooltipPosition="top"
              formControlName="prizeTitleEn"
              [maxlength]="70"
            />
          </div>
        </div>
      </form>
      <div
        class="flex flex-column justify-content-between align-items-start my-1 gap-4"
      >
        <div class="flex flex-column gap-2 w-full">
          <label for="voucherImageBtn">{{ t('voucherImage') }}</label>
          <div class="flex align-items-center gap-4 w-full">
            <img
              id="voucherImageBtn"
              class="border-round"
              *ngIf="voucherForm?.get('imageUrl')?.value"
              height="100px"
              [src]="voucherForm?.get('imageUrl')?.value"
            />

            <p-button
              label="{{ t('chooseImage') }} *"
              icon="pi pi-file-import"
              (click)="voucherInput.value = null; voucherInput.click()"
              pTooltip="{{ t('voucherImage-tooltip') }}"
              tooltipPosition="top"
            ></p-button>

            <input
              #voucherInput
              id="voucher"
              type="file"
              accept="image/*"
              class="hidden"
              (change)="voucherFileChangeEvent($event)"
            />
            <image-cropper
              [imageChangedEvent]="voucherImageChangedEvent"
              [maintainAspectRatio]="true"
              [aspectRatio]="16 / 9"
              format="png"
              (imageCropped)="voucherImageCropped($event)"
            ></image-cropper>
          </div>
        </div>

        <div class="flex flex-row justify-content-end w-full">
          <button
            pButton
            pRipple
            [loading]="voucherSaving"
            [disabled]="voucherForm.invalid"
            label="{{ t('btn-save') }}"
            icon="pi pi-save"
            (click)="uploadVoucher()"
            loadingIcon="pi pi-spin pi-spinner"
            iconPos="right"
          ></button>
        </div>
      </div>
    </div>
    <div
      class="px-0 py-4 md:px-4 surface-card shadow-2 border-round mt-4"
      *ngIf="role === 1"
    >
      <h1 class="m-0 text-900 text-3xl font-medium line-height-3">
        {{ t('dangerZone') }}
      </h1>
      <div
        class="flex flex-row justify-content-between align-items-center my-1"
      >
        <div class="flex flex-column w-9">
          <div class="font-bold">{{ t('deleteWorkspace') }}</div>
          <div class="mb-0 mt-0 text-base text-600">
            {{ t('deleteWorkspace-desc') }}
          </div>
        </div>
        <button
          pButton
          type="button"
          label="{{ t('deleteWorkspaceBtn') }}"
          class="p-button-danger w-3"
          (click)="deleteWorkspace()"
        ></button>
      </div>
      <div
        class="flex flex-row justify-content-between align-items-center my-1"
      >
        <div class="flex flex-column w-9">
          <div class="font-bold">{{ t('transfer') }}</div>
          <div class="mb-0 mt-0 text-base text-600">
            {{ t('transfer-desc') }}
          </div>
        </div>
        <button
          pButton
          type="button"
          label="{{ t('transfer') }}"
          class="p-button-danger w-3"
          (click)="showOwner(t('transfer'))"
        ></button>
      </div>
    </div>
  </div>
</ng-container>
