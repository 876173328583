import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { FileUploadEvent } from 'primeng/fileupload';

@Component({
  selector: 'sweetpopcorn-submission-upload',
  templateUrl: './submission-upload.component.html',
  styleUrls: ['./submission-upload.component.scss'],
  providers: [MessageService],
})
export class SubmissionUploadComponent implements OnInit {
  uploadedFiles: any[] = [];
  uploadCode: string;
  constructor(
    private messageService: MessageService,

    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.uploadCode = params?.['code'];
    });
  }

  onUpload(event: FileUploadEvent) {
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }

    this.messageService.add({
      severity: 'info',
      summary: 'File Uploaded',
      detail: '',
    });
  }
}
