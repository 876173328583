import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';

export interface FAQ {
  i18nQuestion: string;
  i18nAnswer: string;
  position: number;
}

@Component({
  selector: 'sweetpopcorn-faq',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    AccordionModule,
    AvatarGroupModule,
    AvatarModule,
    ButtonModule,
    RippleModule,
  ],
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
  faqs: FAQ[] = [
    {
      i18nAnswer: 'faq-1-answer',
      i18nQuestion: 'faq-1-question',
      position: 1,
    },
    {
      i18nAnswer: 'faq-2-answer',
      i18nQuestion: 'faq-2-question',
      position: 2,
    },
    {
      i18nAnswer: 'faq-3-answer',
      i18nQuestion: 'faq-3-question',
      position: 3,
    },
    {
      i18nAnswer: 'faq-4-answer',
      i18nQuestion: 'faq-4-question',
      position: 4,
    },
    {
      i18nAnswer: 'faq-5-answer',
      i18nQuestion: 'faq-5-question',
      position: 5,
    },
    {
      i18nAnswer: 'faq-6-answer',
      i18nQuestion: 'faq-6-question',
      position: 6,
    },
    {
      i18nAnswer: 'faq-7-answer',
      i18nQuestion: 'faq-7-question',
      position: 7,
    },
    {
      i18nAnswer: 'faq-8-answer',
      i18nQuestion: 'faq-8-question',
      position: 8,
    },
    {
      i18nAnswer: 'faq-9-answer',
      i18nQuestion: 'faq-9-question',
      position: 9,
    },
    {
      i18nAnswer: 'faq-10-answer',
      i18nQuestion: 'faq-10-question',
      position: 10,
    },
    {
      i18nAnswer: 'faq-11-answer',
      i18nQuestion: 'faq-11-question',
      position: 11,
    },
    {
      i18nAnswer: 'faq-12-answer',
      i18nQuestion: 'faq-12-question',
      position: 12,
    },
    {
      i18nAnswer: 'faq-13-answer',
      i18nQuestion: 'faq-13-question',
      position: 13,
    },
    {
      i18nAnswer: 'faq-14-answer',
      i18nQuestion: 'faq-14-question',
      position: 14,
    },
  ];
}
