import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  UsersService,
  WorkspaceDto,
  WorkspaceRole,
  WorkspaceUserDto,
  WorkspacesService,
} from '@sweetpopcorn/api-connector';
import { NotificationToastService } from '@sweetpopcorn/common';
import { UserDialogComponent } from '@sweetpopcorn/ui';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  Observable,
  Subject,
  combineLatest,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-workspace-user-list',
  templateUrl: './workspace-user-list.component.html',
  styleUrls: ['./workspace-user-list.component.scss'],
  providers: [ConfirmationService],
})
export class WorkspaceUserListComponent implements OnInit {
  inviteEmail: string;
  loading = false;
  activeRole = 4;
  adminTitle = 'User Details';
  ref: DynamicDialogRef;
  roles = { Owner: 1, Admin: 2, Editor: 3, Viewer: 4 };
  reloadSubj = new Subject<void>();
  workspace$: Observable<WorkspaceDto>;
  users$: Observable<WorkspaceUserDto[]>;
  canEdit: boolean;
  notificationMessage: { success: string; error: string };
  private workspaceId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private workspaceService: WorkspacesService,
    public dialogService: DialogService,
    private userService: UsersService,
    private notificationToastService: NotificationToastService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    const workspaceId$ = this.activatedRoute.parent.paramMap.pipe(
      map((paramMap) => paramMap.get('workspaceId')),
      filter((id) => !!id)
    );

    this.users$ = combineLatest([
      workspaceId$,
      this.reloadSubj.asObservable().pipe(startWith(null)),
    ]).pipe(
      map(([workspaceId, _]) => workspaceId),
      switchMap((workspaceId) =>
        this.userService.getWorkspaceUsers(workspaceId, 1, 1000)
      ),
      map((users) => users.items)
    );

    this.workspace$ = workspaceId$.pipe(
      switchMap((workspaceId) =>
        this.workspaceService.getWorkspaceById(workspaceId)
      ),
      tap((workspace) => {
        this.canEdit = !workspace?.isLocked;
        this.activeRole = this.roles[workspace.currentUserRole];
        this.workspaceId = workspace.id;
      })
    );
    this.workspace$.pipe(untilDestroyed(this)).subscribe();

    this.translocoService.langChanges$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.setTranslations());
  }

  showBasicDialog(user: WorkspaceUserDto) {
    this.ref = this.dialogService.open(UserDialogComponent, {
      header: this.adminTitle,
      width: '50%',
      data: {
        user: user,
        activeRole: this.activeRole,
        workspaceId: this.workspaceId,
      },
    });

    this.ref.onClose.pipe(untilDestroyed(this)).subscribe(() => {
      this.reloadSubj.next();
    });
  }

  inviteUser() {
    this.workspace$.subscribe((w) => {
      this.workspaceService
        .workspaceInviteUser(w.id, {
          userEmail: this.inviteEmail,
        })
        .subscribe({
          next: () => {
            this.notificationToastService.successMessage(
              this.notificationMessage.success,
              ''
            );
            this.reloadSubj.next();
            this.inviteEmail = '';
          },
          error: () => {
            this.notificationToastService.errorMessage(
              this.notificationMessage.error,
              ''
            );
            this.inviteEmail = '';
          },
        });
    });
  }

  removeUser(user: WorkspaceUserDto) {
    this.workspace$.subscribe((w) => {
      this.workspaceService
        .workspaceRemoveUser(w.id, user.id)
        .subscribe(() => this.reloadSubj.next());
    });

    // this.confirmationService.confirm({
    //   message: 'Are you sure you want to delete this user?',
    //   accept: () => {
    //     this.workspaceService
    //       .workspaceRemoveUser(
    //         this.challengeEditorService.workspace.id,
    //         user.id
    //       )
    //       .subscribe(() => {
    //         this.refreshUserSubject.next();
    //         this.notificationToastService.successMessage(
    //           'User was removed',
    //           user.firstName + ' ' + user.lastName
    //         );
    //       });
    //   },
    // });
  }

  public IsAtLeastAdmin(role: WorkspaceRole): boolean {
    return role === WorkspaceRole.Owner || role === WorkspaceRole.Admin;
  }

  setTranslations() {
    this.notificationMessage = {
      success: this.translocoService.translate('addUser-message'),
      error: this.translocoService.translate('addUser-errormessage'),
    };
    this.adminTitle = this.translocoService.translate('admin-title');
  }
}
