<p-fileUpload
  name="files"
  url="api/submissions/{{ this.uploadCode }}/upload"
  [auto]="true"
  (onUpload)="onUpload($event)"
  [multiple]="true"
  accept="image/*"
>
  <ng-template pTemplate="content">
    <div class="grid" *ngIf="uploadedFiles.length">
      <li *ngFor="let file of uploadedFiles">
        {{ file.name }} - {{ file.size }} bytes
      </li>
    </div>
  </ng-template>
</p-fileUpload>
