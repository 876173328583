<ng-container *transloco="let t">
  <div class="flex flex-column flex-auto">
    <div class="surface-section px-4 py-4 lg:py-5 lg:px-6 h-full border-round">
      <div
        class="flex flex-column md:flex-row w-full justify-content-between md:align-items-center"
      >
        <div class="flex flex-row w-full justify-content-between">
          <h2 class="mt-0 mb-2 text-900 font-medium text-2xl">
            {{ t('settings') }}
          </h2>
          <p-button
            icon="pi pi-times"
            styleClass="p-button-rounded p-button-danger p-button-text"
            [routerLink]="['']"
          ></p-button>
          <!--          <p class="mt-0 mb-0 text-500">-->
          <!--            Vivamus id nisl interdum, blandit augue sit amet, eleifend mi.-->
          <!--          </p>-->
        </div>
      </div>
      <p-divider styleClass="my-5"></p-divider>

      <div class="p-fluid flex flex-column lg:flex-row">
        <ul
          class="list-none m-0 p-0 flex flex-row lg:flex-column gap-1 justify-content-between lg:justify-content-start mb-5 lg:mb-0"
        >
          <li>
            <a
              pRipple
              class="lg:w-15rem flex align-items-center cursor-pointer p-3 border-round hover:surface-200 transition-duration-150 transition-colors"
              [routerLinkActive]="'surface-200'"
              [routerLink]="['/user', 'profile']"
            >
              <i
                class="pi pi-user md:mr-2 text-600"
                [routerLinkActive]="'text-700'"
              ></i>
              <span
                class="font-medium hidden md:block text-700"
                [routerLinkActive]="'text-800'"
                >{{ t('profile') }}</span
              >
            </a>
          </li>
          <li>
            <a
              pRipple
              class="lg:w-15rem flex align-items-center cursor-pointer p-3 border-round hover:surface-200 transition-duration-150 transition-colors"
              [routerLinkActive]="'surface-200'"
              [routerLink]="['/user', 'billing']"
            >
              <i
                class="pi pi-credit-card md:mr-2 text-600"
                [routerLinkActive]="'text-700'"
              ></i>
              <span
                class="font-medium hidden md:block text-700"
                [routerLinkActive]="'text-800'"
                >{{ t('billing') }}</span
              >
            </a>
          </li>
        </ul>
        <div class="surface-card p-5 shadow-2 border-round flex-auto xl:ml-5">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</ng-container>
