import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'sweetpopcorn-workspace-agb',
  templateUrl: './workspace-agb.component.html',
  styleUrls: ['./workspace-agb.component.scss'],
})
export class WorkspaceAgbComponent {
  @Output() nextPage: EventEmitter<number> = new EventEmitter<number>();

  form = this.fb.group({
    acceptedAGB: [false, Validators.requiredTrue],
  });

  constructor(private fb: FormBuilder) {}

  createWorkspace() {
    this.nextPage.emit(1);
  }
}
