import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SubmissionDto, SubmissionsService } from '@sweetpopcorn/api-connector';
import {
  NotificationToastService,
  WorkspaceDetailService,
} from '@sweetpopcorn/common';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import {
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-submission-overview',
  templateUrl: './submission-overview.component.html',
  styleUrls: ['./submission-overview.component.scss'],
  providers: [ConfirmationService, NotificationToastService],
})
export class SubmissionOverviewComponent implements OnInit {
  items$: Observable<SubmissionDto[]>;
  reloadSubj = new Subject<void>();

  nextPage = 1;
  nextPageSize = 10;
  hasMoreData = true;
  totalCount = 0;
  userRole: number;

  constructor(
    private submissionService: SubmissionsService,
    private notificationToastService: NotificationToastService,
    private confirmationService: ConfirmationService,
    private activatedRoute: ActivatedRoute,
    private workspaceDetailService: WorkspaceDetailService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    const workspaceId$ = this.activatedRoute.parent.parent.paramMap.pipe(
      map((paramMap) => paramMap.get('workspaceId')),
      distinctUntilChanged()
    );

    this.items$ = combineLatest([
      workspaceId$,
      this.reloadSubj.asObservable(),
    ]).pipe(
      map(([workspaceId, _]) => workspaceId),
      switchMap((workspaceId) =>
        this.submissionService.getSubmissionsOfWorkspace(
          workspaceId,
          this.nextPage,
          this.nextPageSize
        )
      ),
      tap((data) => {
        this.hasMoreData = data.hasNextPage;
        this.totalCount = data.totalCount;
      }),
      map((data) => data.items)
    );
    this.userRole = this.workspaceDetailService.userRole;
  }

  loadData(event: LazyLoadEvent) {
    this.nextPage = event.first / event.rows + 1;
    this.reloadSubj.next();
  }

  deleteSubmission(submission: SubmissionDto) {
    this.confirmationService.confirm({
      message: this.translocoService.translate(
        'delete-submission-confirmation'
      ),
      accept: () => {
        this.submissionService.deleteSubmission(submission?.id).subscribe({
          next: () => {
            this.notificationToastService.infoMessage(
              this.translocoService.translate('info-submissiondeleted'),
              ''
            );
            this.reloadSubj.next();
          },
          error: (error) => {
            this.notificationToastService.errorMessage(
              this.translocoService.translate('info-submissiondeletedFailed'),
              JSON.stringify(error)
            );
          },
        });
      },
    });
  }
}
