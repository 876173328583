<ng-container *transloco="let t">
  <form [formGroup]="form">
    <div class="flex flex-column gap-4 mt-4 mb-4 p-4">
      <h2 class="text-color-secondary">{{ t('gtc-info') }}</h2>
      <div>
        <p [innerHTML]="t('gtc-text')"></p>
      </div>
      <div class="flex flex-row gap-4">
        <p-checkbox
          formControlName="acceptedAGB"
          [binary]="true"
          id="accept"
        ></p-checkbox>
        <label
          for="accept"
          [routerLink]="['/gtc']"
          class="cursor-pointer underline"
          >{{ t('gtc-button') }}</label
        >
      </div>
    </div>

    <button
      pButton
      pRipple
      label="{{ t('btn-next') }}"
      class="w-full"
      [disabled]="form.invalid"
      (click)="createWorkspace()"
    ></button>
  </form>
</ng-container>
