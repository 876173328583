<ng-container *transloco="let t">
  <div
    class="px-0 py-4 md:px-4 surface-card shadow-2 border-round mt-4"
    *ngIf="challenge$ | async as challenge"
  >
    <div
      class="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative"
    >
      <div>
        <div class="text-900 text-3xl font-medium mb-3">
          {{ challenge?.title }}
        </div>
        <p class="mt-0 mb-3 text-700 text-xl">{{ challenge?.description }}</p>
        <div class="text-600 font-medium">
          <span
            >{{ challenge?.submissionCount }} {{ t('submission') }} |
            {{
              challenge?.submissionStartTime
                | dfnsParseIso
                | dfnsFormat : 'dd.MM.yyyy HH:mm'
            }}
            |
            {{
              challenge?.submissionEndTime
                | dfnsParseIso
                | dfnsFormat : 'dd.MM.yyyy HH:mm'
            }}
          </span>
        </div>
        <div class="flex mt-1 mb-1 align-items-center">
          <p-tag
            *ngIf="challenge?.isActive"
            value="{{ t('status-active') }}"
            severity="success"
            class="mr-1"
          ></p-tag>
          <p-tag
            *ngIf="challenge?.isScheduled"
            value="{{ t('status-scheduled') }}"
            severity="info"
            class="mr-1"
          ></p-tag>
          <p-tag
            *ngIf="challenge?.hasWinningSubmission"
            value="{{ t('status-finished') }}"
            severity="success"
            class="mr-1"
          ></p-tag>
          <p-tag
            *ngIf="challenge?.isClosed"
            value="{{ t('status-closed') }}"
            severity="danger"
            class="mr-1"
          ></p-tag>
          <p-tag
            *ngIf="challenge?.prize?.image?.id"
            value="{{ t('status-active-voucher') }}"
            class="mr-1"
          ></p-tag>
          <p-tag
            *ngIf="challenge?.hasWinningSubmission"
            severity="success"
            value="{{ t('winner') | uppercase }}"
            [style]="{ 'z-index': '500' }"
          ></p-tag>
        </div>
      </div>
      <div class="mt-3 lg:mt-0">
        <p-button
          *ngIf="userRole < 4"
          label="{{ t('deleteChallenge-btn') }}"
          icon="pi pi-trash"
          styleClass="p-button-danger"
          (click)="deleteChallenge(challenge)"
        ></p-button>
      </div>
    </div>
  </div>

  <p-confirmDialog #cd header="Confirmation" icon="pi pi-exclamation-triangle">
    <ng-template pTemplate="footer">
      <button
        type="button"
        pButton
        icon="pi pi-times"
        label="{{ t('btn-no') }}"
        (click)="cd.reject()"
      ></button>
      <button
        type="button"
        pButton
        icon="pi pi-check"
        label="{{ t('btn-yes') }}"
        (click)="cd.accept()"
      ></button>
    </ng-template>
  </p-confirmDialog>

  <div class="px-0 py-4 md:px-4 surface-card shadow-2 border-round mt-4">
    <p-dataView
      [value]="submissions$ | async"
      [paginator]="true"
      [lazy]="true"
      [rows]="nextPageSize"
      filterBy="name"
      layout="grid"
      (onLazyLoad)="loadData($event)"
      [totalRecords]="totalCount"
    >
      <ng-template pTemplate="header">
        <div class="flex flex-column md:flex-row gap-2">
          <div class="flex justify-content-center align-items-center mr-2">
            Filter:
          </div>
          <p-toggleButton
            [(ngModel)]="checked"
            onLabel="=="
            offLabel=">="
            (onChange)="changeSort()"
          ></p-toggleButton>
          <p-dropdown
            [options]="sortOptions"
            [(ngModel)]="filterValue"
            [placeholder]="starPlaceholder"
            (onChange)="onSortChange($event)"
            styleClass="mb-2 md:mb-0"
          ></p-dropdown>
        </div>
      </ng-template>

      <ng-template let-item pTemplate="gridItem">
        <div class="col-12 sm:col-6 lg:col-4 xl:col-3">
          <div
            class="m-1 p-1 border-1 surface-border surface-card border-round"
          >
            <div class="relative">
              <div class="absolute ml-2" [style]="{ 'z-index': '500' }">
                <p-tag
                  *ngIf="item?.isWinningSubmission"
                  severity="success"
                  value="{{ t('winner') }}"
                  [style]="{ 'z-index': '500' }"
                ></p-tag>
              </div>

              <div
                class="flex flex-column align-items-center overflow-hidden mt-1"
              >
                <sweetpopcorn-video
                  class="mb-1"
                  *ngIf="item?.contentType.includes('video')"
                  [backgroundImageSource]="item?.thumbnail?.url"
                  [videoSrc]="item?.file?.url"
                ></sweetpopcorn-video>
                <p-image
                  *ngIf="item?.contentType.includes('image')"
                  [previewImageSrc]="item?.file?.url"
                  [imageStyle]="{
                    'max-width': '250px',
                    'max-height': '250px',
                    'object-fit': 'cover'
                  }"
                  [src]="item?.thumbnail?.url"
                  alt="Image"
                  width="250"
                  height="250"
                  [preview]="true"
                >
                </p-image>
              </div>
            </div>

            <div class="flex flex-row justify-content-center p-2">
              <div class="flex align-items-center">
                <p-rating
                  [(ngModel)]="item.rating"
                  (ngModelChange)="ratingChanged(item, $event)"
                ></p-rating>
              </div>

              <div>
                <p-splitButton
                  styleClass="p-button-text customSplit mb-1"
                  #ua
                  icon="pi pi-cloud-download"
                  (onClick)="downloadImage(item)"
                  (onDropdownClick)="selectContent(item)"
                  [model]="imageOptions"
                ></p-splitButton>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </div>
</ng-container>
