<ng-container *transloco="let t">
  <p-confirmDialog
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
  ></p-confirmDialog>
  <div class="px-0 py-4 md:px-4 surface-card shadow-2 border-round mt-4">
    <div class="flex flex-column w-full p-2">
      <p-table
        #dt1
        [globalFilterFields]="['firstName', 'lastName', 'email', 'role']"
        [loading]="loading"
        [paginator]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [rows]="10"
        [showCurrentPageReport]="true"
        [value]="users$ | async"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-row gap-4">
            <h1 class="m-0 text-900 text-3xl font-medium line-height-3 mb-3">
              {{ t('workspaceUsers') }}
            </h1>
            <div class="flex-grow-1"></div>
            <p-button
              label="{{ t('inviteUser') }}"
              class="flex-grow-0"
              icon="pi pi-user-plus"
              [disabled]="!canEdit && activeRole > 2"
              (click)="op.toggle($event)"
            ></p-button>
          </div>
          <p-overlayPanel #op>
            <ng-template pTemplate="menu">
              <div class="p-inputgroup">
                <input
                  type="text"
                  pInputText
                  placeholder="e.g. user@mail.com"
                  [(ngModel)]="inviteEmail"
                />
                <p-button
                  label="{{ t('invite') }}"
                  class="flex-grow-0 ml-1"
                  (click)="inviteUser(); op.toggle($event)"
                ></p-button>
              </div>
            </ng-template>
          </p-overlayPanel>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="username">
              {{ t('username') }}
              <p-sortIcon field="username"></p-sortIcon>
            </th>
            <th pSortableColumn="firstName">
              {{ t('firstName') }}
              <p-sortIcon field="firstName"></p-sortIcon>
            </th>
            <th pSortableColumn="lastName">
              {{ t('lastName') }}
              <p-sortIcon field="lastName"></p-sortIcon>
            </th>
            <th pSortableColumn="email">
              Email
              <p-sortIcon field="email"></p-sortIcon>
            </th>
            <th pSortableColumn="role">
              {{ t('tableUser-role') }}
              <p-sortIcon field="role"></p-sortIcon>
            </th>
            <th>{{ t('Actions') }}</th>
          </tr>
        </ng-template>
        <ng-template let-user pTemplate="body">
          <tr>
            <td>{{ user?.username }}</td>
            <td>{{ user?.firstName }}</td>
            <td>{{ user?.lastName }}</td>
            <td>
              {{ user?.email }}
            </td>
            <td>
              {{ user?.role }}
            </td>
            <td>
              <div
                *ngIf="activeRole < 3 && user.role !== 'Owner'"
                class="flex justify-content-end gap-2"
              >
                <div>
                  <a
                    class="mt-2 md:mt-0 flex flex-nowrap cursor-pointer bg-primary hover:bg-blue-500 text-white p-3 border-round"
                    (click)="showBasicDialog(user)"
                    ><i class="pi pi-user-edit"></i
                  ></a>
                </div>
                <div>
                  <a
                    class="mt-2 md:mt-0 flex flex-nowrap cursor-pointer bg-gray-800 hover:bg-gray-500 text-white p-3 border-round"
                    (click)="removeUser(user)"
                    ><i class="pi pi-minus-circle"></i
                  ></a>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">{{ t('dataViewer-emptyMessage') }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</ng-container>
