<ng-container *transloco="let t">
  <div class="h-screen">
    <sweetpopcorn-navbar class="w-full"></sweetpopcorn-navbar>
    <div
      class="flex flex-column variableHeight w-full justify-content-between mt-2"
    >
      <div>
        <router-outlet></router-outlet>
        <sweetpopcorn-notification-toast></sweetpopcorn-notification-toast>
      </div>
      <div class="surface-section mt-2 px-4 md:px-6 lg:px-8">
        <div
          class="py-6 flex flex-column sm:flex-row sm:align-items-center justify-content-between"
        >
          <div
            class="flex flex-column sm:flex-row sm:align-items-center justify-content-between gap-4"
          >
            <div [routerLink]="['faq']" class="cursor-pointer">FAQs</div>
            <div [routerLink]="['impressum']" class="cursor-pointer">
              {{ t('impressum-title') }}
            </div>
            <div [routerLink]="['gtc']" class="cursor-pointer">
              {{ t('create-agb-tab') }}
            </div>
          </div>
          <div>
            <div class="mt-2 line-height-3">&copy; 2024 {{ t('rights') }}</div>
          </div>
          <div class="mt-3 sm:mt-0">
            <a
              class="cursor-pointer text-500 transition-colors transition-duration-150 hover:text-700"
              href="https://pividro.com/"
              target="_blank"
            >
              <i class="pi pi-globe text-xl"></i>
            </a>
            <a
              class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700"
              href="mailto:office@sweetpopcorn.studio"
              target="_blank"
            >
              <i class="pi pi-send text-xl"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
