import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from '@sweetpopcorn/api-connector';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-user-billing',
  templateUrl: './user-billing.component.html',
  styleUrls: ['./user-billing.component.scss'],
})
export class UserBillingComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {}

  async manageBilling() {
    this.userService
      .createCustomerPortal()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        window.location.href = result.url;
      });
  }
}
