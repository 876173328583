import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ChallengeDto,
  ChallengesService,
  SubmissionDto,
  SubmissionItemDto,
  SubmissionsService,
} from '@sweetpopcorn/api-connector';
import {
  NotificationToastService,
  WorkspaceDetailService,
} from '@sweetpopcorn/common';
import {
  ConfirmationService,
  LazyLoadEvent,
  MenuItem,
  SelectItem,
} from 'primeng/api';
import {
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-challenge-detail',
  templateUrl: './challenge-detail.component.html',
  styleUrls: ['./challenge-detail.component.scss'],
  providers: [ConfirmationService, NotificationToastService],
})
export class ChallengeDetailComponent implements OnInit {
  challenge$: Observable<ChallengeDto>;
  submissions$: Observable<SubmissionDto[]>;
  reloadSubj = new Subject<void>();

  nextPage = 1;
  nextPageSize = 12;
  hasMoreData = true;
  totalCount = 0;
  starPlaceholder = '';
  sortOptions!: SelectItem[];
  starValueExact = null;
  starValueMin = null;
  checked = false;
  filterValue: SelectItem;
  downloadConfirmation: { header: string; message: string };
  DeleteConfirmation: { header: string; message: string };
  setWinnerconfirmationDialog: {
    header: string;
    message: string;
    notificationMessage: string;
  };
  selectedContent: SubmissionItemDto;
  imageOptions: MenuItem[];
  userRole: number;

  constructor(
    private route: ActivatedRoute,
    private challengeService: ChallengesService,
    private submissionService: SubmissionsService,
    private translocoService: TranslocoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private submissionsService: SubmissionsService,
    private notificationToastService: NotificationToastService,
    private workspaceDetailService: WorkspaceDetailService
  ) {}

  ngOnInit(): void {
    const challengeId$ = this.route.paramMap.pipe(
      map((paramMap) => paramMap.get('challengeId')),
      distinctUntilChanged()
    );

    this.submissions$ = combineLatest([
      challengeId$,
      this.reloadSubj.asObservable(),
    ]).pipe(
      map(([challengeId, _]) => challengeId),
      switchMap((challengeId) =>
        this.submissionService.getSubmissionItemsOfChallenge(
          challengeId,
          this.starValueMin,
          this.starValueExact,
          this.nextPage,
          this.nextPageSize
        )
      ),
      tap((data) => {
        this.hasMoreData = data.hasNextPage;
        this.totalCount = data.totalCount;
      }),
      map((data) => data.items)
    );

    this.challenge$ = challengeId$.pipe(
      switchMap((challengeId) =>
        this.challengeService.getChallengeById(challengeId)
      )
    );

    this.challenge$.subscribe((value) => {
      if (!value.hasWinningSubmission && !value.isActive) {
        this.setRatingItems(false);
      } else {
        this.setRatingItems(true);
      }
    });

    this.userRole = this.workspaceDetailService.userRole;
  }

  loadData(event: LazyLoadEvent) {
    this.nextPage = event.first / event.rows + 1;
    this.reloadSubj.next();
  }

  async downloadImage(item: SubmissionItemDto) {
    this.challenge$.subscribe((value) => {
      if (value.isActive) {
        this.confirmationService.confirm({
          message: this.downloadConfirmation.message,
          header: this.downloadConfirmation.header,
          icon: 'pi pi-info-circle',
          accept: () => {
            this.download(item);
          },
        });
      } else {
        this.download(item);
      }
    });
  }

  download(item: SubmissionItemDto) {
    const link = document.createElement('a');
    link.href = item?.file?.url;
    link.download = 'image file name here';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  ratingChanged(item: SubmissionItemDto, value: number) {
    if (!value) {
      value = 0;
    }
    this.submissionService
      .rateSubmissionItem(item?.id, { rating: value })
      .subscribe();
  }

  onSortChange(event: any) {
    const value = event.value;
    if (this.checked) {
      this.starValueExact = value;
      this.starValueMin = null;
    } else {
      this.starValueExact = null;
      this.starValueMin = value;
    }

    this.reloadSubj.next();
  }

  changeSort() {
    if (this.filterValue) {
      this.onSortChange({ value: this.filterValue });
    }
  }

  selectContent(item: SubmissionItemDto) {
    this.selectedContent = item;
  }
  navigateToSubmission(item: SubmissionItemDto) {
    this.router.navigate(['..', 'submissions', item.submissionId], {
      relativeTo: this.activatedRoute.parent,
    });
  }

  deleteConfirmation(item: SubmissionItemDto) {
    this.confirmationService.confirm({
      message: this.DeleteConfirmation.message,
      header: this.DeleteConfirmation.header,
      icon: 'pi pi-info-circle',
      accept: () => {
        this.submissionsService.deleteSubmissionItem(item.id).subscribe(() => {
          this.notificationToastService.infoMessage(
            'Item was deleted',
            item.id
          );
          this.reloadSubj.next();
        });
      },
    });
  }

  setWinnerConfirmation(item: SubmissionItemDto) {
    this.challenge$.subscribe((value) => {
      if (!value.isActive) {
        this.confirmationService.confirm({
          message: this.setWinnerconfirmationDialog.message,
          header: this.setWinnerconfirmationDialog.header,
          icon: 'pi pi-info-circle',
          accept: () => {
            this.setWinner(item);
          },
        });
      } else {
        this.setWinner(item);
      }
    });
  }

  setWinner(item: SubmissionItemDto) {
    this.submissionService
      .setWinningSubmission(item.submissionId)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.notificationToastService.successMessage(
          this.setWinnerconfirmationDialog.notificationMessage,
          ''
        );
        this.reloadSubj.next();
      });
  }

  setRatingItems(activeWinner: boolean) {
    this.starPlaceholder = this.translocoService.translate('starRaiting');
    this.sortOptions = [
      { label: this.translocoService.translate('noRating'), value: null },
      { label: '5 ' + this.translocoService.translate('star'), value: 5 },
      { label: '4 ' + this.translocoService.translate('star'), value: 4 },
      { label: '3 ' + this.translocoService.translate('star'), value: 3 },
      { label: '2 ' + this.translocoService.translate('star'), value: 2 },
      { label: '1 ' + this.translocoService.translate('star'), value: 1 },
      { label: '0 ' + this.translocoService.translate('star'), value: 0 },
    ];

    this.imageOptions = [
      {
        visible: this.userRole < 4,
        icon: 'pi pi-dollar',
        label: this.translocoService.translate('winner'),
        disabled: activeWinner,
        command: (event) => {
          this.setWinnerConfirmation(this.selectedContent);
          activeWinner = true;
          this.imageOptions[0].disabled = true;
        },
      },
      {
        icon: 'pi pi-info-circle',
        label: this.translocoService.translate('submission'),
        command: () => {
          this.navigateToSubmission(this.selectedContent);
        },
      },
      {
        visible: this.userRole < 4,
        icon: 'pi pi-trash',
        label: this.translocoService.translate('deleteWorkspaceBtn'),
        command: (event) => {
          this.deleteConfirmation(this.selectedContent);
        },
      },
    ];

    this.downloadConfirmation = {
      header: this.translocoService.translate('downloadConfirmation-header'),
      message: this.translocoService.translate('downloadConfirmation-message'),
    };

    this.setWinnerconfirmationDialog = {
      header: this.translocoService.translate('confirmation'),
      message: this.translocoService.translate('setWinnerConfirmation-message'),
      notificationMessage: this.translocoService.translate(
        'winnernotification-message'
      ),
    };
    this.DeleteConfirmation = {
      header: this.translocoService.translate('deleteItemConfirmation-header'),
      message: this.translocoService.translate(
        'deleteItemConfirmation-message'
      ),
    };
  }

  deleteChallenge(challenge: ChallengeDto) {
    this.confirmationService.confirm({
      message: this.translocoService.translate('deleteChallenge-btn'),
      accept: () => {
        this.challengeService.deleteChallenge(challenge?.id).subscribe({
          next: () => {
            this.reloadSubj.next();
            this.notificationToastService.successMessage(
              this.translocoService.translate('challenge'),
              this.translocoService.translate('deleteChallenge-message')
            );
            this.router.navigate(['..', 'challenges'], {
              relativeTo: this.activatedRoute.parent,
            });
          },
          error: (error) => {
            this.notificationToastService.errorMessage(
              'Could not delete submission',
              JSON.stringify(error)
            );
          },
        });
      },
    });
  }
}
