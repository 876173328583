<ng-container *transloco="let t">
  <ng-container *ngIf="$workspace | async as workspace; else loading">
    <div class="px-0 py-4 md:px-4 surface-card shadow-2 border-round">
      <div class="surface-section">
        <div class="flex flex-row pb-4 surface-border align-items-center">
          <img
            *ngIf="workspace?.file?.url"
            width="80px"
            height="80px"
            [src]="workspace?.file?.url"
            class="border-round mr-3"
          />
          <div class="mb-3 w-9">
            <div class="flex flex-row justify-content-between w-full">
              <div class="text-3xl font-medium text-900 mb-2">
                {{ workspace?.name }}
              </div>
              <div class="flex-grow-1"></div>
              <button
                *ngIf="workspace?.isLocked"
                pButton
                [label]="t('btn-continuePayment')"
                (click)="retryPayment(workspace)"
              ></button>
            </div>
            <div class="ml-auto">
              <span
                ><i class="pi pi-briefcase mr-2"></i
                >{{ workspace?.currentUserRole }}</span
              >
              <span class="px-2">|</span>
              <span
                ><i class="pi pi-images mr-2"></i
                >{{ workspace?.submissionItemCount }} Uploads</span
              >
              <span class="px-2">|</span>
              <span
                ><i class="pi pi-book mr-2"></i
                >{{ workspace?.submissionCount }} {{ t('submission') }}</span
              >
            </div>
          </div>

          <div class="flex flex-row gap-2 ml-2">
            <div
              *ngIf="
                (workspace?.prize?.title ? false : true) && !workspace?.isLocked
              "
            >
              <p-tag
                severity="danger"
                [value]="t('tag-danger-voucher')"
                [rounded]="true"
                [pTooltip]="t('infobox-Prize')"
                tooltipPosition="left"
                placeholder="Top"
                class="cursor-pointer"
                (click)="navigateSettings()"
              ></p-tag>
            </div>
          </div>
        </div>

        <div class="text-500 mb-2">
          <p class="desc">
            {{
              lang === 'de' ? workspace?.description : workspace?.descriptionEn
            }}
          </p>
        </div>
      </div>
      <p-tabMenu [model]="items"></p-tabMenu>
    </div>
    <router-outlet></router-outlet>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="border-round border-1 surface-border p-4 surface-card">
    <div class="flex mb-3">
      <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
      <div>
        <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton height=".5rem"></p-skeleton>
      </div>
    </div>
    <p-skeleton width="100%" height="150px"></p-skeleton>
    <div class="flex justify-content-between mt-3">
      <p-skeleton width="4rem" height="2rem"></p-skeleton>
      <p-skeleton width="4rem" height="2rem"></p-skeleton>
    </div>
  </div>
</ng-template>
