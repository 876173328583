import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Subject, debounceTime, takeUntil } from 'rxjs';

@Component({
  selector: 'sweetpopcorn-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent implements OnDestroy {
  @Input()
  placeholderText: string;

  @Input()
  actionButton: { text: string; routerLink?: any };

  @Output()
  searchChanged: EventEmitter<string> = new EventEmitter<string>();

  searchText: string;
  private searchDebouncer: Subject<string> = new Subject<string>();

  private destroyed$: Subject<boolean> = new Subject();

  constructor() {
    this.searchDebouncer
      .pipe(debounceTime(150), takeUntil(this.destroyed$))
      .subscribe((value: string) => this.searchChanged.emit(value));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  searchOnChange(value: string) {
    this.searchDebouncer.next(value);
  }
}
