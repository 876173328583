import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ApiConnectorApiModule,
  ApiConnectorConfiguration,
} from '@sweetpopcorn/api-connector';
import { AuthInterceptor } from '@sweetpopcorn/common';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { DateFnsModule } from 'ngx-date-fns';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxQrcodeStylingModule } from 'ngx-qrcode-styling';
import { NgxStripeModule } from 'ngx-stripe';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { DividerModule } from 'primeng/divider';
import { GalleriaModule } from 'primeng/galleria';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WorkspaceBillingModule } from './create-workspace/workspace-billing.module';
import { GtcComponent } from './gtc/gtc.component';
import { HelpComponent } from './help/help.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { NavbarModule } from './navbar/navbar.module';
import { NotificationToastModule } from './notification-toast/notification-toast.module';
import { TranslocoRootModule } from './transloco-root.module';
import { UserBillingComponent } from './user/user-billing/user-billing.component';
import { UserContainerComponent } from './user/user-container/user-container.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { WorkspaceDetailModule } from './workspace-detail/workspace-detail.module';

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.authUrl,
        realm: 'dropsocial',
        clientId: 'dropsocial',
      },
      loadUserProfileAtStartUp: false,
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
      },
      bearerExcludedUrls: [
        'assets',
        'assets/icons/',
        'assets/images/',
        'assets/svgs/',
        'assets/styles/',
        'assets/i18n/',
      ],
    });
}

@NgModule({
  declarations: [
    AppComponent,
    UserContainerComponent,
    UserProfileComponent,
    UserBillingComponent,
    HelpComponent,
    ImpressumComponent,
    GtcComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ApiConnectorApiModule,
    HammerModule,
    HttpClientModule,
    AppRoutingModule,
    KeycloakAngularModule,
    NotificationToastModule,
    NavbarModule,
    WorkspaceDetailModule,
    WorkspaceBillingModule,
    TooltipModule,
    DateFnsModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripeKey),
    NgxQrcodeStylingModule,
    DividerModule,
    FormsModule,
    CheckboxModule,
    InputTextareaModule,
    RippleModule,
    ButtonModule,
    ChipsModule,
    ReactiveFormsModule,
    GalleriaModule,
    TranslocoRootModule,
    AccordionModule,
    AvatarGroupModule,
    AvatarModule,
    ImageCropperModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ApiConnectorConfiguration,
      useValue: new ApiConnectorConfiguration({
        basePath: window.location.origin,
      }),
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
