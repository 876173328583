<ng-container *transloco="let t">
  <p-confirmDialog
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
  ></p-confirmDialog>

  <div class="">
    <ul class="list-none p-0 m-0">
      <li class="surface-0">
        <div class="surface-0">
          <div class="field">
            <div class="field">
              <label class="col-12 mb-2 md:col-4 md:mb-0" for="username">
                {{ t('username') }}:
              </label>
              <span class="p-input-icon-left" id="username">
                <p>{{ userName }}</p>
              </span>
            </div>
            <div class="field">
              <label class="col-12 mb-2 md:col-4 md:mb-0" for="name">
                {{ t('name') }}:
              </label>
              <span class="p-input-icon-left" id="name">
                {{ firstName }} {{ lastName }}
              </span>
            </div>
            <div class="field">
              <label class="col-12 mb-2 md:col-4 md:mb-0" for="mail"
                >Email:</label
              >
              <span class="p-input-icon-left" id="mail">
                <p>{{ this.config.data.user.email }}</p>
              </span>
            </div>
          </div>
        </div>
      </li>
      <li class="surface-0">
        <div class="surface-0">
          <div class="field">
            <div class="field">
              <label class="col-12 mb-2 md:col-4 md:mb-0" for="role">
                {{ t('admin-role-label') }}
              </label>
              <span id="role">
                <p-dropdown
                  [(ngModel)]="selectedRole"
                  [options]="roles"
                  optionLabel="name"
                ></p-dropdown>
              </span>
            </div>
          </div>
        </div>
      </li>
      <!--    <li class="surface-0">-->
      <!--      <div class="surface-0">-->
      <!--        <h3>Ownership</h3>-->
      <!--        <p-divider></p-divider>-->
      <!--        <div *ngIf="owner" class="field col-12">-->
      <!--          <button-->
      <!--            (click)="transferOwnership()"-->
      <!--            class="p-button-danger"-->
      <!--            label="Transfer Ownership"-->
      <!--            pButton-->
      <!--            type="button"-->
      <!--            icon="pi pi-check"-->
      <!--          ></button>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </li>-->
    </ul>
  </div>

  <div class="p-dialog-footer">
    <div class="flex flex-row gap-8 justify-content-start">
      <div class="field col-2">
        <button
          (click)="cancelChanges()"
          class="p-button-text p-button-plain"
          icon="pi pi-times"
          [label]="t('cancel')"
          pButton
          type="button"
        ></button>
      </div>
      <div class="field col-2">
        <button
          (click)="submitChanges()"
          icon="pi pi-check"
          [label]="t('btn-save')"
          pButton
          type="button"
        ></button>
      </div>
    </div>
  </div>
</ng-container>
