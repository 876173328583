import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullWithDefault',
  standalone: true,
})
export class NullWithDefaultPipe implements PipeTransform {
  transform(value: any, defaultText = ''): any {
    if (typeof value === 'undefined' || value === null || value == 'null') {
      return defaultText;
    }

    return value;
  }
}
