import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { WorkspaceRole, WorkspacesService } from '@sweetpopcorn/api-connector';
import { NotificationToastService } from '@sweetpopcorn/common';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'sweetpopcorn-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss'],
  providers: [ConfirmationService],
})
export class UserDialogComponent implements OnInit {
  userName: string;
  firstName: string;
  lastName: string;
  roles: { name: string }[];
  selectedRole: { name: string };

  owner = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private workspaceService: WorkspacesService,
    private notificationToastService: NotificationToastService,
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService
  ) {
    this.roles = [{ name: 'Admin' }, { name: 'Editor' }, { name: 'Viewer' }];
    this.selectedRole = { name: this.config.data.user.role };
  }

  ngOnInit() {
    this.userName = this.config?.data?.user?.username;
    this.firstName = this.config?.data?.user?.firstName;
    this.lastName = this.config?.data?.user?.lastName;
    if (this.config.data.activeRole === 1) this.owner = true;
  }

  submitChanges() {
    if (WorkspaceRole[this.selectedRole.name] == 'Admin') {
      this.workspaceService
        .workspaceAssignAdminRole(
          this.config.data.workspaceId,
          this.config.data.user.id
        )
        .subscribe({
          next: (value) => {
            this.notificationToastService.successMessage(
              this.translocoService.translate('changeUser-message'),
              value
            );
          },
          error: () => {
            this.notificationToastService.errorMessage(
              this.translocoService.translate('changeUser-errormessage'),
              ''
            );
          },
        });
    } else {
      this.workspaceService
        .workspaceAssignBasicRole(
          this.config.data.workspaceId,
          this.config.data.user.id,
          { newRole: WorkspaceRole[this.selectedRole.name] }
        )
        .subscribe({
          next: (value) => {
            this.notificationToastService.successMessage(
              this.translocoService.translate('changeUser-message'),
              value
            );
            this.ref.close({ success: true });
          },
          error: () => {
            this.notificationToastService.errorMessage(
              this.translocoService.translate('changeUser-errormessage'),
              ''
            );
          },
        });
    }

    this.ref.close({ success: false });
  }

  transferOwnership() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to transfer workspace ownership?',
      accept: () => {
        this.workspaceService
          .workspaceTransferOwnership(
            this.config.data.workspaceId,
            this.config.data.user.id
          )
          .subscribe({
            next: () => {
              this.notificationToastService.successMessage(
                'Ownership',
                'Ownership was transferred'
              );
              this.ref.close({ success: true });
            },
            error: () => {
              this.notificationToastService.errorMessage(
                'Ownership',
                'Ownership could not be transferred. Please try again.'
              );
            },
          });
      },
    });
  }

  cancelChanges() {
    this.ref.close({ success: false });
  }
}
