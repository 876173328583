<ng-container *transloco="let t">
  <div
    class="p-4 surface-section"
    *ngIf="userProfile$ | async as user; else loading"
  >
    <div class="flex justify-content-between align-items-center">
      <div class="text-3xl mb-4 capitalize">{{ t('yourWorkspace') }}</div>
      <p-button
        icon="pi pi-plus"
        [routerLink]="['/create-workspace']"
      ></p-button>
    </div>
    <ng-container *ngIf="workspaces$ | async as workspaces">
      <ul class="list-none p-0 m-0">
        <li
          *ngFor="let workspace of workspaces"
          class="flex flex-column sm:flex-row sm:justify-content-between sm:align-items-center p-2 mb-2 cursor-pointer border-round hover:bg-gray-300 text-gray-300 hover:text-white transition-duration-150 transition-colors no-underline"
          [routerLink]="['/workspace', workspace?.id]"
        >
          <div class="flex align-items-center">
            <span
              class="block mr-3 border-radius: 10px inline-flex justify-content-center align-items-center"
              style="width: 58px; height: 58px; border-radius: 10px"
            >
              <ng-container *ngIf="workspace.file; else templateA">
                <p-avatar
                  styleClass="p-mr-2 border-round-md"
                  size="xlarge"
                  image="{{ workspace.file?.url }}"
                  shape="circle"
                ></p-avatar>
              </ng-container>
              <ng-template #templateA>
                <p-avatar
                  size="xlarge"
                  icon="pi pi-folder"
                  styleClass="p-mr-2 border-round-md"
                ></p-avatar>
              </ng-template>
            </span>
            <div>
              <div class="text-900 font-medium text-xl mb-2">
                {{ workspace?.name }}
              </div>
            </div>
          </div>
          <div class="sm:text-right mt-3 md:mt-0">
            <div class="text-sm text-600 mt-4">
              {{ workspace?.owner?.id === user.id ? t('ownWorkspace') : '' }}
            </div>
          </div>
        </li>
      </ul>
      <div *ngIf="workspaces?.length === 0">
        <div class="mb-3">
          {{ t('noWorkspace-subtitle') }}
        </div>
        <p-button
          label="{{ t('create-workspace') }}"
          icon="pi pi-plus"
          [routerLink]="['/create-workspace']"
        ></p-button>
        <div class="mt-3">
          {{ t('noWorkspace-text') }}
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #loading>
  <div class="border-round border-1 surface-border p-4 surface-card">
    <ul class="m-0 p-0 list-none">
      <li class="mb-3">
        <div class="flex">
          <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
          <div style="flex: 1">
            <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="75%"></p-skeleton>
          </div>
        </div>
      </li>
      <li class="mb-3">
        <div class="flex">
          <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
          <div style="flex: 1">
            <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="75%"></p-skeleton>
          </div>
        </div>
      </li>
      <li class="mb-3">
        <div class="flex">
          <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
          <div style="flex: 1">
            <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="75%"></p-skeleton>
          </div>
        </div>
      </li>
      <li>
        <div class="flex">
          <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
          <div style="flex: 1">
            <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="75%"></p-skeleton>
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-template>
