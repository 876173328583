import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ActionBarComponent } from './action-bar.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    RouterLink,
    InputTextModule,
    FormsModule,
  ],
  declarations: [ActionBarComponent],
  exports: [ActionBarComponent],
})
export class ActionBarModule {}
