<ng-container *transloco="let t">
  <span>{{ t('transfer-msg') }}</span>
  <div class="flex flex-column">
    <div *ngIf="data.CompanyName; else privateUser">
      <div class="flex flex-column gap-2 mt-4">
        <label for="compName">{{ t('companyName-label') }}</label>
        <input pInputText id="compName" [value]="data.CompanyName" disabled />
      </div>
    </div>

    <ng-template #privateUser>
      <div class="flex flex-column gap-2 mt-4">
        <div class="flex flex-column gap-2">
          <label for="firstName">{{ t('firstName') }}</label>
          <input pInputText id="firstName" [value]="data.FirstName" disabled />
        </div>

        <div class="flex flex-column gap-2">
          <label for="lastName">{{ t('lastName') }}</label>
          <input pInputText id="lastName" [value]="data.LastName" disabled />
        </div>

        <div class="flex flex-column gap-2">
          <label for="dateOfBirth">{{ t('birthdate-label') }}</label>
          <input
            pInputText
            id="dateOfBirth"
            [value]="data.DateOfBirth | date : 'dd.MM.YYYY'"
            disabled
          />
        </div>
      </div>
    </ng-template>

    <div class="flex flex-column gap-2 mt-2">
      <div class="flex flex-column gap-2">
        <label for="address1">{{ t('addressrow-label') }} 1</label>
        <input pInputText id="address1" [value]="data.AddressLine1" disabled />
      </div>

      <div class="flex flex-column gap-2">
        <label for="address2">{{ t('addressrow-label') }} 2</label>
        <input pInputText id="address2" [value]="data.AddressLine2" disabled />
      </div>

      <div class="flex flex-column gap-2">
        <label for="zipCode">{{ t('plz-label') }}</label>
        <input pInputText id="zipCode" [value]="data.PostalCode" disabled />
      </div>

      <div class="flex flex-column gap-2">
        <label for="city">{{ t('city-label') }}</label>
        <input pInputText id="city" [value]="data.City" disabled />
      </div>

      <div class="flex flex-column gap-2">
        <label for="country">{{ t('country-label') }}</label>
        <input pInputText id="country" [value]="'Austria'" disabled />
      </div>
    </div>

    <p-button
      class="mt-4"
      [style]="{ width: '100%' }"
      [label]="t('btn-back')"
      (click)="closeDialog()"
    ></p-button>
  </div>
</ng-container>
