import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ChallengesService,
  SubmissionDto,
  SubmissionItemDto,
  SubmissionsService,
} from '@sweetpopcorn/api-connector';
import {
  NotificationToastService,
  WorkspaceDetailService,
} from '@sweetpopcorn/common';
import { ConfirmationService, LazyLoadEvent, SelectItem } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-submission-detail',
  templateUrl: './submission-detail.component.html',
  styleUrls: ['./submission-detail.component.scss'],
  providers: [ConfirmationService, NotificationToastService],
})
export class SubmissionDetailComponent implements OnInit, OnDestroy {
  submission$: Observable<SubmissionDto>;
  submissionItems$: Observable<SubmissionItemDto[]>;
  reloadSubj = new Subject<void>();
  starPlaceholder = '';

  nextPage = 1;
  nextPageSize = 12;
  hasMoreData = true;
  totalCount = 0;

  sortOptions!: SelectItem[];
  starValueExact = null;
  starValueMin = null;
  checked = false;
  filterValue: SelectItem;
  isChallengeActive$: Observable<boolean>;
  downloadConfirmation: { header: string; message: string };
  DeleteConfirmation: { header: string; message: string; notification: string };
  DeleteSubmissionConfirmation: {
    header: string;
    message: string;
    notification: string;
  };
  userRole: number;

  ref: DynamicDialogRef | undefined;

  constructor(
    private route: ActivatedRoute,
    private submissionService: SubmissionsService,
    private confirmationService: ConfirmationService,
    private notificationToastService: NotificationToastService,
    private challengesService: ChallengesService,
    private translocoService: TranslocoService,
    private navigator: Router,
    private workspaceDetailService: WorkspaceDetailService
  ) {}

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }
  ngOnInit(): void {
    const submissionId$ = this.route.paramMap.pipe(
      map((paramMap) => paramMap.get('submissionId')),
      distinctUntilChanged()
    );

    this.submissionItems$ = combineLatest([
      submissionId$,
      this.reloadSubj.asObservable(),
    ]).pipe(
      map(([submissionId, _]) => submissionId),
      switchMap((submissionId) =>
        this.submissionService.getSubmissionItemsById(
          submissionId,
          this.starValueMin,
          this.starValueExact,
          this.nextPage,
          this.nextPageSize
        )
      ),
      tap((data) => {
        this.hasMoreData = data.hasNextPage;
        this.totalCount = data.totalCount;
      }),
      map((data) => data.items)
    );

    this.submission$ = submissionId$.pipe(
      switchMap((submissionId) =>
        this.submissionService.getSubmissionById(submissionId)
      )
    );

    this.isChallengeActive$ = this.submission$.pipe(
      filter((sub) => !!sub.challengeId),
      switchMap((sub) =>
        this.challengesService.getChallengeById(sub.challengeId)
      ),
      map((challenge) => challenge.isActive),
      untilDestroyed(this)
    );

    this.translocoService.langChanges$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.translocoService.selectTranslate('confirmation').subscribe(() => {
          this.setRatingItems();
        });
      });

    this.userRole = this.workspaceDetailService.userRole;
  }

  loadData(event: LazyLoadEvent) {
    this.nextPage = event.first / event.rows + 1;
    this.reloadSubj.next();
  }

  async downloadImage(item: SubmissionItemDto) {
    this.submission$.subscribe((value) => {
      if (value.challengeId) {
        this.isChallengeActive$.subscribe((active) => {
          if (active) {
            this.confirmationService.confirm({
              message: this.downloadConfirmation.message,
              header: this.downloadConfirmation.header,
              icon: 'pi pi-info-circle',
              accept: () => {
                this.download(item);
              },
            });
          } else {
            this.download(item);
          }
        });
      } else {
        this.download(item);
      }
    });
  }

  async download(item: SubmissionItemDto) {
    const link = document.createElement('a');
    link.href = item?.file?.url;
    link.download = 'image file name here';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  ratingChanged(item: SubmissionItemDto, value: number) {
    if (!value) {
      value = 0;
    }
    this.submissionService
      .rateSubmissionItem(item?.id, { rating: value })
      .subscribe();
  }

  onSortChange(event: any) {
    const value = event.value;
    if (this.checked) {
      this.starValueExact = value;
      this.starValueMin = null;
    } else {
      this.starValueExact = null;
      this.starValueMin = value;
    }

    this.reloadSubj.next();
  }
  changeSort() {
    if (this.filterValue) {
      this.onSortChange({ value: this.filterValue });
    }
  }

  deleteConfirmation(item: SubmissionItemDto) {
    this.confirmationService.confirm({
      message: this.DeleteConfirmation.message,
      header: this.DeleteConfirmation.header,
      icon: 'pi pi-info-circle',
      accept: () => {
        this.submissionService.deleteSubmissionItem(item.id).subscribe(() => {
          this.notificationToastService.infoMessage(
            this.DeleteConfirmation.notification,
            item.id
          );
          this.reloadSubj.next();
        });
      },
    });
  }
  deleteSubmission(submission: SubmissionDto) {
    this.confirmationService.confirm({
      message: this.DeleteSubmissionConfirmation.message,
      header: this.DeleteSubmissionConfirmation.header,
      icon: 'pi pi-info-circle',
      accept: () => {
        this.submissionService.deleteSubmission(submission?.id).subscribe({
          next: () => {
            this.notificationToastService.infoMessage(
              this.DeleteSubmissionConfirmation.notification,
              submission?.email
            );
            this.navigator.navigate(['..'], { relativeTo: this.route });
          },
          error: (error) => {
            this.notificationToastService.errorMessage(
              this.translocoService.translate('info-submissiondeletedFailed'),
              JSON.stringify(error)
            );
          },
        });
      },
    });
  }

  setRatingItems() {
    this.starPlaceholder = this.translocoService.translate('starRaiting');
    this.sortOptions = [
      { label: this.translocoService.translate('noRating'), value: null },
      { label: '5 ' + this.translocoService.translate('star'), value: 5 },
      { label: '4 ' + this.translocoService.translate('star'), value: 4 },
      { label: '3 ' + this.translocoService.translate('star'), value: 3 },
      { label: '2 ' + this.translocoService.translate('star'), value: 2 },
      { label: '1 ' + this.translocoService.translate('star'), value: 1 },
      { label: '0 ' + this.translocoService.translate('star'), value: 0 },
    ];
    this.downloadConfirmation = {
      header: this.translocoService.translate('downloadConfirmation-header'),
      message: this.translocoService.translate('downloadConfirmation-message'),
    };
    this.DeleteConfirmation = {
      header: this.translocoService.translate('deleteItemConfirmation-header'),
      message: this.translocoService.translate(
        'deleteItemConfirmation-message'
      ),
      notification: this.translocoService.translate('message-item-deleted'),
    };
    this.DeleteSubmissionConfirmation = {
      header: this.translocoService.translate('confirmation'),
      message: this.translocoService.translate(
        'confirmation-delete-submission'
      ),
      notification: this.translocoService.translate(
        'message-submission-deleted'
      ),
    };
  }

  downloadContract() {
    this.submission$.subscribe((value) => {
      this.submissionService
        .getSubmissionContractById(value.id)
        .subscribe((value) => {
          window.open(value.url);
        });
    });
  }
}
