<ng-container *transloco="let t">
  <div class="px-0 py-4 md:px-4 surface-card shadow-2 border-round mt-4 mb-5">
    <ngx-qrcode-styling
      #qrcode
      hidden
      [config]="config"
      [type]="'canvas'"
      [shape]="'square'"
      [width]="1000"
      [height]="1000"
      [margin]="5"
      [data]="'Angular QRCode'"
      [image]="'./assets/images/PiViDro_Light.svg'"
    >
    </ngx-qrcode-styling>

    <div class="flex flex-column gap-5">
      <div class="flex flex-column gap-2">
        <span class="text-3xl">{{ t('workspaceUpload') }}</span>
        <div
          id="workspaceUpload"
          class="flex flex-row justify-content-around w-full"
        >
          <input
            class="w-7 cursor-pointer"
            type="text"
            pInputText
            value="{{ environment.uploadUrl + slugName }}"
            (click)="copyInputMessage(workspaceurl)"
            #workspaceurl
          />
          <p-button
            (click)="copyInputMessage(workspaceurl)"
            label="{{ t('copytoClipboard') }}"
          ></p-button>
          <p-button
            (click)="
              updateQrCode(
                workspaceImageUrl,
                workspaceurl.value,
                'pividro_qr_workspace'
              )
            "
            label="{{ t('copyQrCode') }}"
          ></p-button>
        </div>
      </div>
      <div class="flex flex-column gap-4">
        <span class="text-3xl">{{ t('challengesUpload') }}</span>
        <div
          id="challengesUpload"
          class="flex flex-column gap-3 w-full"
          *ngIf="items?.length > 0; else info"
        >
          <ul *ngFor="let w of items" class="w-full p-0 m-0">
            <div class="flex flex-column gap-2">
              <span>{{ w.title }}</span>
              <div class="flex flex-row justify-content-around w-full">
                <input
                  class="w-7 cursor-pointer"
                  type="text"
                  pInputText
                  value="{{ environment.uploadUrl + slugName }}/challenge/{{
                    w.id
                  }}"
                  #challengeurl
                  (click)="copyInputMessage(challengeurl)"
                />
                <p-button
                  (click)="copyInputMessage(challengeurl)"
                  label="{{ t('copytoClipboard') }}"
                ></p-button>
                <p-button
                  (click)="
                    updateQrCode(
                      workspaceImageUrl,
                      challengeurl.value,
                      'pividro_challenge_' + w.title
                    )
                  "
                  label="{{ t('copyQrCode') }}"
                ></p-button>
              </div>
            </div>
          </ul>
        </div>
        <ng-template #info>
          <span>{{ t('share-info') }}</span>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
