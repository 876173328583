import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RippleModule } from 'primeng/ripple';

import { DropdownModule } from 'primeng/dropdown';
import { SidebarModule } from 'primeng/sidebar';
import { StyleClassModule } from 'primeng/styleclass';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { NavbarComponent } from './navbar.component';

@NgModule({
  declarations: [NavbarComponent],
  imports: [
    CommonModule,
    RippleModule,
    StyleClassModule,
    BadgeModule,
    InputTextModule,
    RouterLink,
    OverlayPanelModule,
    FormsModule,
    ButtonModule,
    RouterLinkActive,
    AvatarModule,
    SidebarModule,
    TranslocoDirective,
    ToggleButtonModule,
    DropdownModule,
    TooltipModule,
  ],
  providers: [],
  exports: [NavbarComponent],
})
export class NavbarModule {}
