<ng-container *transloco="let t">
  <div class="relative">
    <div class="flex bg-gray-800" style="min-height: 66px">
      <a
        class="flex cursor-pointer px-6 align-items-center hover:bg-gray-500 transition-colors transition-duration-150 text-3xl no-underline"
        [routerLink]="['']"
      >
        <img
          src="assets/images/PiViDro_Dark.svg"
          class="h-full w-8rem"
          alt="logo"
        />
      </a>
      <div *ngIf="!hideDropDown">
        <div
          *ngIf="currentWorkspace$ | async as currentWorkspace"
          class="cursor-pointer flex px-3 py-2 align-items-center hover:bg-gray-500 transition-colors transition-duration-150 relative z-5"
          pStyleClass="#workspace-dropdown"
          enterClass="hidden"
          enterActiveClass="fadein"
          leaveToClass="hidden"
          leaveActiveClass="fadeout"
          [hideOnOutsideClick]="true"
        >
          <p-avatar
            *ngIf="currentWorkspace?.file?.url"
            [image]="currentWorkspace?.file?.url"
            shape="circle"
            size="large"
            styleClass="mr-2"
          ></p-avatar>

          <p-avatar
            *ngIf="!currentWorkspace?.file?.url"
            [label]="currentWorkspace?.name"
            shape="circle"
            size="large"
            styleClass="mr-2"
          ></p-avatar>

          <div class="hidden lg:block select-none">
            <span
              class="block mb-1 text-white text-lg font-medium white-space-nowrap"
              >{{ currentWorkspace?.name }}</span
            >
            <div class="text-gray-200">
              <span class="mr-2"
                ><i class="pi pi-book mr-1"></i
                >{{ currentWorkspace?.submissionCount }}</span
              >
              <span class=""
                ><i class="pi pi-images mr-1"></i
                >{{ currentWorkspace?.submissionItemCount }}</span
              >
            </div>
          </div>
          <i class="pi pi-chevron-down text-white ml-3 lg:ml-5"></i>

          <div
            id="workspace-dropdown"
            class="hidden absolute left-0 top-100 shadow-2 bg-gray-800 w-full origin-top z-10"
          >
            <ul class="list-none m-0 px-0 z-10">
              <li *ngFor="let w of workspaces$ | async">
                <a
                  [routerLink]="['workspace', w?.slug]"
                  class="no-underline flex p-3 align-items-center text-white hover:bg-gray-500 transition-colors transition-duration-150 select-none"
                >
                  <p-avatar
                    *ngIf="w?.file?.url"
                    [image]="w?.file?.url"
                    shape="circle"
                    size="large"
                    styleClass="mr-2"
                  ></p-avatar>

                  <p-avatar
                    *ngIf="!w?.file?.url"
                    [label]="w?.name"
                    shape="circle"
                    size="large"
                    styleClass="mr-2"
                  ></p-avatar>
                  <div class="flex flex-column">
                    <span class="font-medium">{{ w?.name }}</span>
                    <div class="text-gray-200">
                      <span class="mr-2"
                        ><i class="pi pi-book mr-1"></i
                        >{{ w?.submissionCount }}</span
                      >
                      <span class=""
                        ><i class="pi pi-images mr-1"></i
                        >{{ w?.submissionItemCount }}</span
                      >
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a
                  pRipple
                  [routerLink]="['create-workspace']"
                  class="flex p-3 align-items-center text-white hover:bg-gray-500 transition-colors transition-duration-150 select-none no-underline"
                >
                  <span class="font-medium">{{ t('create-workspace') }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ng-container *ngIf="currentUser$ | async as currentUser">
        <div class="flex-1 flex align-items-center">
          <div class="flex-grow-1"></div>
          <!--          <div class="text-white">-->
          <!--            <i-->
          <!--              class="pi pi-question-circle mr-2 cursor-pointer"-->
          <!--              [routerLink]="['/help']"-->
          <!--              style="font-size: 1.5rem"-->
          <!--              tooltipPosition="left"-->
          <!--              [pTooltip]="t('help-page-pre-title')"-->
          <!--            ></i>-->
          <!--          </div>-->
          <div class="text-white flex flex-row align-items-center">
            <div>
              <p-dropdown
                [options]="languageOptions"
                (onChange)="updateLanguage($event)"
                class="w-10rem"
                [(ngModel)]="currentLang"
                [autoDisplayFirst]="false"
              >
                <ng-template pTemplate="selectedItem">
                  <div class="flex flex-row justify-content-between">
                    <img
                      [src]="currentLang.flag"
                      style="width: 18px"
                      alt="currentflag"
                    />
                    <div class="uppercase ml-2">{{ currentLang.code }}</div>
                  </div>
                </ng-template>

                <ng-template let-lang pTemplate="item">
                  <div class="flex flex-row justify-content-around">
                    <img
                      [src]="lang.flag"
                      style="width: 18px"
                      alt="translationflag"
                    />
                    <div class="uppercase">{{ lang.code }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div
            class="cursor-pointer flex h-full w-10rem p-3 align-items-center hover:bg-gray-500 transition-colors transition-duration-150 relative"
            pStyleClass="#profile-dropdown"
            enterClass="hidden"
            enterActiveClass="scalein"
            leaveToClass="hidden"
            leaveActiveClass="fadeout"
            [hideOnOutsideClick]="true"
          >
            <!--          <img src="assets/images/blocks/avatars/circle/avatar-f-6.png" class="lg:mr-3" style="width: 40px; height: 40px"/>-->
            <div class="hidden lg:block select-none">
              <span
                class="text-white block mb-1 text-lg font-medium white-space-nowrap overflow-hidden text-overflow-ellipsis"
                style="width: 100px"
                >{{ currentUser?.username }}</span
              >
              <p class="m-0 text-white">{{ currentUser?.role }}</p>
            </div>
            <i class="pi pi-chevron-down text-white ml-3 hidden lg:block"></i>
            <div
              id="profile-dropdown"
              class="absolute left-0 top-100 shadow-2 bg-gray-800 w-full origin-top hidden z-1"
            >
              <ul class="list-none m-0 z-10 p-0">
                <li [routerLink]="['/', 'user', 'profile']">
                  <a
                    pRipple
                    class="flex p-3 align-items-center text-white hover:bg-gray-500 transition-colors transition-duration-150"
                  >
                    <i class="pi pi-user mr-2"></i>
                    <span class="font-medium">{{ t('profile') }}</span>
                  </a>
                </li>

                <li [routerLink]="['/', 'user', 'billing']">
                  <a
                    pRipple
                    class="flex p-3 align-items-center text-white hover:bg-gray-500 transition-colors transition-duration-150"
                  >
                    <i class="pi pi-credit-card mr-2"></i>
                    <span class="font-medium">{{ t('billing') }}</span>
                  </a>
                </li>
                <li (click)="signOut()">
                  <a
                    pRipple
                    class="flex p-3 align-items-center text-white hover:bg-gray-500 transition-colors transition-duration-150"
                  >
                    <i class="pi pi-sign-out mr-2"></i>
                    <span class="font-medium">{{ t('signout') }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
