import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export interface ownerData {
  FirstName: string;
  LastName: string;
  DateOfBirth: string;
  AddressLine1: string;
  AddressLine2: string;
  PostalCode: string;
  City: string;
  Country: string;
  CompanyName: string;
  CompanyVat: string;
  CompanyDataProtectionUrl: string;
}
@Component({
  selector: 'sweetpopcorn-workspace-owner',
  templateUrl: './workspace-owner.component.html',
  styleUrls: ['./workspace-owner.component.scss'],
})
export class WorkspaceOwnerComponent implements OnInit {
  data: ownerData;

  constructor(
    public config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {}
  ngOnInit() {
    this.data = this.config.data;
  }

  closeDialog() {
    this.ref.close();
  }
}
