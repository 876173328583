<div class="flex flex-column w-full p-4">
  <form [formGroup]="form" class="flex flex-column flex-grow-1">
    <div class="flex-auto p-fluid">
      <div class="mb-4">
        <label for="name" class="block font-medium text-900 mb-2"
          >Workspace Password (optional)</label
        >
        <input id="name" type="text" pInputText formControlName="password" />
      </div>
      <div class="flex flex-column justify-content-around gap-4 mb-4">
        <div class="w-full">
          <label for="firstName" class="block font-medium text-900 mb-2"
            >First Name</label
          >
          <input
            id="firstName"
            type="text"
            pInputText
            formControlName="firstName"
          />
        </div>
        <div class="w-full">
          <label for="lastName" class="block font-medium text-900 mb-2"
            >Last Name</label
          >
          <input
            id="lastName"
            type="text"
            pInputText
            formControlName="lastName"
          />
        </div>
        <div class="w-full">
          <label for="email" class="block font-medium text-900 mb-2"
            >Email</label
          >
          <input id="email" type="text" pInputText formControlName="email" />
        </div>
      </div>
      <div class="w-full">
        <label for="customerReference" class="block font-medium text-900 mb-2"
          >Customer Reference</label
        >
        <input
          id="customerReference"
          type="text"
          pInputText
          formControlName="customerReference"
        />
      </div>
    </div>
  </form>

  <div class="flex flex-column gap-4 mt-4">
    <button
      pButton
      pRipple
      label="Save Submission"
      class="w-auto"
      [disabled]="!form.valid"
      (click)="saveSubmission()"
    ></button>
  </div>
</div>
