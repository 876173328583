import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  FileDto,
  FileService,
  UploadLimit,
  WorkspaceWithBillingInfoDto,
  WorkspacesService,
} from '@sweetpopcorn/api-connector';
import {
  NotificationToastService,
  WorkspaceDetailService,
  WorkspaceService,
} from '@sweetpopcorn/common';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, merge, switchMap, toArray } from 'rxjs';
import { WorkspaceOwnerComponent } from '../workspace-owner/workspace-owner.component';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-workspace-form',
  templateUrl: './workspace-form.component.html',
  styleUrls: ['./workspace-form.component.scss'],
  providers: [ConfirmationService],
})
export class WorkspaceFormComponent implements OnInit, AfterViewInit {
  maxDescriptionLength = 1000;
  passwordChanged = false;
  workspace$: Observable<WorkspaceWithBillingInfoDto>;
  private voucherInfoMessage = 'Voucher uploaded';
  workspaceId: string;
  role = 1;
  saving = false;
  uoloadLimit = [];
  imageChangedEvent: any = '';
  voucherImageChangedEvent: any = '';
  private reg = 'https://www\\.[a-zA-Z0-9-]+(?:\\.[a-zA-Z]{2,})+(?:/[^\\s]*)?';
  form = this.fb.group({
    id: [''],
    slug: [''],
    name: ['', Validators.required],
    description: [''],
    descriptionEn: [''],
    contactUrl: ['', Validators.pattern(this.reg)],
    contactMail: ['', [Validators.required, Validators.email]],
    contactPhone: [''],
    password: [''],
    image: [null],
    imageUrl: [''],
    hasPassword: [false],
    uploadLimit: [UploadLimit.NoLimit],
  });

  voucherForm = this.fb.group({
    id: [''],
    prizeTitle: ['', Validators.required],
    prizeTitleEn: [''],
    prizeDescription: [''],
    prizeDescriptionEn: [''],
    prizeUrl: [''],
    image: [null],
    file: [null],
    imageUrl: ['', Validators.required],
    imageId: [''],
  });
  createNotificationMessage: { title: string; message: string };

  canEdit: boolean;
  voucherSaving = false;

  ref: DynamicDialogRef | undefined;

  constructor(
    private fb: FormBuilder,
    private workspacesService: WorkspacesService,
    private notificationToastService: NotificationToastService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private workspaceDetailService: WorkspaceDetailService,
    private workspaceService: WorkspaceService,
    private translocoService: TranslocoService,
    private dialogService: DialogService,
    private fileService: FileService
  ) {}

  ngAfterViewInit() {
    this.activateRoute.queryParams.subscribe((params: Params) => {
      const section = params['section'];
      if (section) {
        setTimeout(() => {
          this.scrollToSection(section);
        }, 100);
      }
    });
  }

  ngOnInit() {
    this.workspace$ = this.workspaceDetailService.workspace$;
    this.role = this.workspaceDetailService.userRole;

    this.workspace$.pipe(untilDestroyed(this)).subscribe((data) => {
      const contactMail = data?.contactUrl == 'null' ? '' : data?.contactUrl;
      this.form.patchValue({
        id: data?.id,
        slug: data?.slug,
        name: data?.name,
        description: data?.description == 'null' ? '' : data?.description,
        descriptionEn: data?.descriptionEn == 'null' ? '' : data?.descriptionEn,
        contactUrl: data?.contactUrl == 'null' ? '' : data?.contactUrl,
        contactMail: data?.contactMail,
        contactPhone: data?.contactPhone,
        imageUrl: data?.file?.url,
        password: data.hasPassword ? 'placeHolder' : '',
        hasPassword: data.hasPassword,
        uploadLimit: data?.uploadLimit,
      });
      this.detectPasswordChanges();
      this.voucherForm.patchValue({
        id: data?.id,
        prizeTitle: data?.prize?.title,
        prizeTitleEn: data?.prize?.titleEn,
        prizeDescription: data?.prize?.description,
        prizeDescriptionEn: data?.prize?.descriptionEn,
        prizeUrl: data?.prize?.url,
        imageUrl: data?.prize?.image?.url,
        imageId: data?.prize?.image?.id,
      });

      this.canEdit = !data.isLocked;
      if (!this.canEdit) {
        this.form.disable();
        this.voucherForm.disable();
      }
      this.workspaceId = data?.id;
    });
    this.translocoService.langChanges$.subscribe(() => {
      this.updateNotification();
    });
  }

  deletePassword() {
    this.form.patchValue({
      password: '',
    });
  }

  detectPasswordChanges() {
    this.form
      .get('password')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.passwordChanged = true;
      });
  }

  saveChanges() {
    const formData = this.form.getRawValue();
    this.saving = true;
    this.workspacesService
      .updateWorkspace(
        formData?.id,
        formData?.name,
        formData?.slug,
        formData?.description,
        formData?.descriptionEn,
        formData?.contactUrl,
        formData?.contactMail,
        formData?.contactPhone,
        this.passwordChanged ? formData?.password : undefined,
        formData?.image,
        formData?.uploadLimit
      )
      .subscribe(() => {
        this.notificationToastService.successMessage(
          this.createNotificationMessage.title,
          this.createNotificationMessage.message
        );
        this.saving = false;
        this.passwordChanged = false;
        this.workspaceDetailService.reloadWorkspace();
      });
  }

  deleteWorkspace() {
    this.confirmationService.confirm({
      accept: () => {
        this.workspacesService
          .deleteWorkspace(this.form.get('id').value)
          .subscribe(async () => {
            this.workspaceDetailService.reloadWorkspace();
            this.workspaceService.reloadWorkspaces();
            await this.router.navigate(['']);
          });
      },
    });
  }

  uploadVoucher() {
    this.voucherSaving = true;
    this.createOrUpdateVoucher(false);
  }

  uploadVoucherImage(): Observable<FileDto> {
    const voucherData = this.voucherForm.getRawValue();

    const file = new File([voucherData.image], 'defaultImage', {
      type: voucherData.image.type,
    });

    return this.fileService.apiFileUploadWorkspaceIdPost(this.workspaceId, [
      file,
    ]);
    //return this.http.post('/api/file/upload/' + this.workspaceId, formData);
  }

  createOrUpdateVoucher(overwrite = false) {
    let formData = this.voucherForm.getRawValue();

    const observables = [];

    if (formData.image !== null) {
      observables.push(this.uploadVoucherImage());
    }

    merge(...observables)
      .pipe(
        toArray(),
        switchMap((results: FileDto[]) => {
          if (formData.image !== null) {
            this.voucherForm.patchValue({
              imageId: results[0]?.id,
              imageUrl: results[0]?.url,
            });
          }

          formData = this.voucherForm.getRawValue();
          return this.workspacesService.setDefaultPrizeForWorkspace(
            formData.id,
            {
              workspaceIdOrSlug: formData.id,
              prizeTitle: formData?.prizeTitle,
              prizeTitleEn: formData?.prizeTitleEn,
              prizeDescription: 'empty',
              prizeDescriptionEn: 'empty',
              prizeUrl: formData?.prizeUrl,
              imageId: formData?.imageId,
            }
          );
        })
      )
      .subscribe(() => {
        this.notificationToastService.successMessage(
          this.voucherInfoMessage,
          ''
        );
        this.voucherSaving = false;
        this.workspaceDetailService.reloadWorkspace();
      });
  }

  updateNotification() {
    this.createNotificationMessage = {
      title: this.translocoService.translate('yourWorkspace'),
      message: this.translocoService.translate('update-message'),
    };
    this.voucherInfoMessage = this.translocoService.translate('voucher-info');
    this.uoloadLimit = [
      {
        name: this.translocoService.translate('upload-nolimit'),
        value: UploadLimit.NoLimit,
      },
      {
        name: this.translocoService.translate('upload-once'),
        value: UploadLimit.Once,
      },
      {
        name: this.translocoService.translate('upload-daily'),
        value: UploadLimit.Daily,
      },
      {
        name: this.translocoService.translate('upload-weekly'),
        value: UploadLimit.Weekly,
      },
      {
        name: this.translocoService.translate('upload-monthly'),
        value: UploadLimit.Monthly,
      },
      {
        name: this.translocoService.translate('upload-yearly'),
        value: UploadLimit.Yearly,
      },
    ];
  }

  showOwner(header: string) {
    this.workspace$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.ref = this.dialogService.open(WorkspaceOwnerComponent, {
        header: header,
        width: '50vw',
        contentStyle: { overflow: 'auto' },
        data: {
          FirstName: data.firstName,
          LastName: data.lastName,
          DateOfBirth: data.dateOfBirth,
          AddressLine1: data.addressLine1,
          AddressLine2: data.addressLine2,
          PostalCode: data.postalCode,
          City: data.city,
          Country: data.country,
          CompanyName:
            data.companyName === 'null' ? undefined : data.companyName,
          CompanyVat: data.companyVat,
          CompanyDataProtectionUrl: data.companyDataProtectionUrl,
        },
      });
    });
  }

  private scrollToSection(section: string): void {
    const el: HTMLElement | null = document.querySelector('#main_' + section);
    el?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  fileChangeEvent(event: any, filename: string): void {
    this.form.get('image').setValue(filename);
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    // this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);

    this.form.patchValue({ image: event.blob, imageUrl: event.objectUrl });
    // event.blob can be used to upload the cropped image
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  voucherFileChangeEvent(event: any): void {
    this.voucherImageChangedEvent = event;
  }
  voucherImageCropped(event: ImageCroppedEvent) {
    // this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);

    this.voucherForm.patchValue({
      image: event.blob,
      imageUrl: event.objectUrl,
    });
    // event.blob can be used to upload the cropped image
  }
}
