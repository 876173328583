import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { from } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly keycloak: KeycloakService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return from(this.handleAccess(req, next));
  }

  async handleAccess(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.startsWith('assets')) {
      //Find way around this
      return next.handle(req).toPromise();
    }
    const token: string = await this.keycloak.getToken();
    const changedReq = req.clone({
      setHeaders: {
        Authorization: `bearer ${token}`,
      },
    });
    return next.handle(changedReq).toPromise();
  }
}
