<ng-container *transloco="let t">
  <div class="text-900 font-semibold text-lg mt-3">{{ t('billing') }}</div>
  <div class="flex gap-5 flex-column-reverse md:flex-row">
    <div class="flex-auto p-fluid">
      <!--      <p>{{ t('openBilling') }}</p>-->
      <div>
        <button
          pButton
          pRipple
          label="{{ t('openBilling') }}"
          class="w-auto mt-3"
          (click)="manageBilling()"
        ></button>
      </div>
    </div>
  </div>
</ng-container>
