import { Injectable } from '@angular/core';
import {
  ChallengeDto,
  ChallengesService,
  WorkspaceDto,
  WorkspacesService,
} from '@sweetpopcorn/api-connector';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EditorService {
  workspaceSubject: BehaviorSubject<WorkspaceDto> =
    new BehaviorSubject<WorkspaceDto>({});
  workspace$: Observable<WorkspaceDto> = this.workspaceSubject.asObservable();
  workspaceId$: Observable<string> = this.workspace$.pipe(map((w) => w.id));

  get workspace() {
    return this.workspaceSubject.getValue();
  }

  challengeSubject: BehaviorSubject<ChallengeDto> =
    new BehaviorSubject<ChallengeDto>({});
  challenge$: Observable<ChallengeDto> = this.challengeSubject.asObservable();
  get challenge() {
    return this.challengeSubject.getValue();
  }

  constructor(
    private workspaceService: WorkspacesService,
    private challengesService: ChallengesService
  ) {}

  workspaceChanged(workspaceId: string) {
    this.workspaceService
      .getWorkspaceById(workspaceId)
      .subscribe((workspace) => {
        this.workspaceSubject.next(workspace);
      });
  }

  challengeChanged(challengeId: string) {
    if (challengeId) {
      this.challengesService
        .getChallengeById(challengeId)
        .subscribe((challenge) => {
          this.challengeSubject.next(challenge);
        });
    } else {
      this.challengeSubject.next({});
    }
  }
}
