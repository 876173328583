import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthorizationService } from '@sweetpopcorn/common';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  form = this.fb.group({
    firstname: ['', []],
    lastname: [''],
    username: ['', []],
    email: ['', []],
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthorizationService
  ) {}

  ngOnInit() {
    this.disableInputFields();
    this.authService.userProfile$
      .pipe(untilDestroyed(this))
      .subscribe((profile) => {
        this.form.get('firstname').patchValue(profile.firstName);
        this.form.get('lastname').patchValue(profile.lastName);
        this.form.get('username').patchValue(profile.username);
        this.form.get('email').patchValue(profile.email);
      });
  }

  disableInputFields() {
    this.form.get('firstname').disable();
    this.form.get('lastname').disable();
    this.form.get('username').disable();
    this.form.get('email').disable();
  }
}
