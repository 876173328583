import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  WorkspaceDto,
  WorkspaceUserDto,
  WorkspacesService,
} from '@sweetpopcorn/api-connector';
import {
  AuthorizationService,
  WorkspaceDetailService,
} from '@sweetpopcorn/common';
import { Observable, filter, map, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  workspaces$: Observable<WorkspaceDto[]> = this.workspaceService
    .getAllWorkspaces(1, 100)
    .pipe(map((w) => w.items));
  currentWorkspace$: Observable<WorkspaceDto>;
  currentUser$: Observable<WorkspaceUserDto> =
    this.authorizationService.userProfile$;

  languageOptions = [
    {
      code: 'en',
      name: 'EN',
      flag: 'assets/flags/gb.svg',
    },
    {
      code: 'de',
      name: 'DE',
      flag: 'assets/flags/de.svg',
    },
  ];

  currentLang = {
    code: '',
    name: '',
    flag: '',
  };

  constructor(
    private workspaceService: WorkspacesService,
    private authorizationService: AuthorizationService,
    private router: Router,
    private translocoService: TranslocoService,
    private workspaceDetailService: WorkspaceDetailService
  ) {}

  ngOnInit() {
    const id$ = this.router.events.pipe(
      switchMap(() => this.router.routerState.root.firstChild.paramMap),
      map((paramMap) => paramMap.get('workspaceId')),
      filter((id) => !!id)
    );

    this.workspaceDetailService.initWorkspace(id$);
    this.currentWorkspace$ = this.workspaceDetailService.workspace$;

    this.setDefaultLang();
  }

  setDefaultLang() {
    const _currentLang = this.translocoService.getActiveLang();
    this.findAndSetLang(_currentLang);
  }

  findAndSetLang(lang: string) {
    const foundLang = this.languageOptions.find((val) => val.code === lang);
    if (foundLang) {
      this.currentLang = foundLang;
    } else {
      this.currentLang = this.languageOptions[0];
    }
  }

  updateLanguage(event: any) {
    const lang = event?.value || 'de';
    switch (lang.code) {
      case 'de':
        this.setLangauge('de');
        break;
      case 'en':
        this.setLangauge('en');
        break;
      default:
        this.setLangauge('de');
    }
  }

  setLangauge(lang: string) {
    this.translocoService.setActiveLang(lang);
    this.findAndSetLang(lang);
  }

  async createNewWorkspace() {
    await this.router.navigate(['workspace/create']);
  }

  async signOut() {
    await this.authorizationService.signOut();
  }
  get hideDropDown() {
    return this.workspaceDetailService.hideDropDown;
  }
}
