<ng-container *transloco="let t">
  <div class="px-0 py-4 md:px-4 surface-card shadow-2 border-round mt-4">
    <div class="text-900 font-semibold text-lg my-3">
      {{
        !challengeForm?.get('id').value
          ? t('challenge-new')
          : t('challenge-edit')
      }}
    </div>
    <form [formGroup]="challengeForm" class="flex flex-column flex-grow-1">
      <div class="flex-auto p-fluid">
        <div class="mb-4">
          <label for="title" class="block font-medium text-900 mb-2"
            >{{ t('title') }} [{{ t('lang-de') }}] *</label
          >
          <input
            id="title"
            type="text"
            pInputText
            formControlName="title"
            [maxlength]="70"
          />
        </div>
        <div class="mb-4">
          <label for="title" class="block font-medium text-900 mb-2"
            >{{ t('title') }} [{{ t('lang-en') }}]</label
          >
          <input
            id="titleEn"
            type="text"
            pInputText
            formControlName="titleEn"
            [maxlength]="70"
          />
        </div>
        <div class="mb-4">
          <label for="description" class="block font-medium text-900 mb-2"
            >{{ t('description') }} [{{ t('lang-de') }}]
            {{ description.value.length }}/{{
              this.maxDescriptionLength
            }}
            *</label
          >
          <textarea
            id="description"
            type="text"
            pInputTextarea
            rows="5"
            #description
            [maxlength]="maxDescriptionLength"
            [autoResize]="true"
            formControlName="description"
          ></textarea>
        </div>
        <div class="mb-4">
          <label for="descriptionEn" class="block font-medium text-900 mb-2"
            >{{ t('description') }} [{{ t('lang-en') }}]
            {{ descriptionEn.value.length }}/{{
              this.maxDescriptionLength
            }}</label
          >
          <textarea
            id="descriptionEn"
            type="text"
            pInputTextarea
            rows="5"
            #descriptionEn
            [maxlength]="maxDescriptionLength"
            [autoResize]="true"
            formControlName="descriptionEn"
          ></textarea>
        </div>
        <div class="mb-4">
          <label for="visibility" class="block font-medium text-900 mb-2">{{
            t('uploadLimit-label')
          }}</label>
          <p-dropdown
            id="visibility"
            [options]="uoloadLimit"
            optionValue="value"
            formControlName="uploadLimit"
            optionLabel="name"
          ></p-dropdown>
        </div>
        <div class="mb-4">
          <label for="maxFileSize" class="block font-medium text-900 mb-2"
            >Max. {{ t('size') }} [MB] *</label
          >
          <input
            id="maxFileSize"
            type="number"
            pInputText
            formControlName="maxFileSize"
          />
        </div>
        <div class="mb-4">
          <label for="maxSubmissionSize" class="block font-medium text-900 mb-2"
            >Max. {{ t('subamount') }} *</label
          >
          <input
            id="maxSubmissionSize"
            type="number"
            pInputText
            formControlName="maxSubmissionSize"
          />
        </div>
      </div>
      <label for="challengeImg" class="block font-medium text-900 mb-2"
        >{{ t('uploadChallengeImage') }} *</label
      >
      <div class="flex align-items-center gap-4 w-full" id="challengeImg">
        <img
          *ngIf="challengeForm?.get('imgUrl')?.value"
          height="100px"
          [src]="challengeForm?.get('imgUrl')?.value"
        />
        <p-button
          label="{{ t('chooseImage') }}"
          icon="pi pi-file-import"
          [disabled]="!isScheduled"
          (click)="challengeInput.value = null; challengeInput.click()"
        ></p-button>
        <input
          #challengeInput
          id="voucher"
          type="file"
          accept="image/*"
          class="hidden"
          (change)="challengeFileChangeEvent($event)"
        />
        <image-cropper
          [imageChangedEvent]="challengeImageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="1 / 1"
          format="png"
          (imageCropped)="challengeImageCropped($event)"
        ></image-cropper>
      </div>
      <div class="flex flex-column mt-4">
        <label for="SubmissionStart" class="block font-medium text-900 mb-2">{{
          t('submissionStartTime')
        }}</label>
        <p-calendar
          id="SubmissionStart"
          [showTime]="true"
          formControlName="submissionStartTime"
          [dateFormat]="'dd.mm.yy'"
        ></p-calendar>
      </div>
      <div class="flex flex-column mt-4">
        <label for="SubmissionEnd" class="block font-medium text-900 mb-2">{{
          t('submissionEndTime')
        }}</label>
        <p-calendar
          id="SubmissionEnd"
          [showTime]="true"
          formControlName="submissionEndTime"
          [dateFormat]="'dd.mm.yy'"
        ></p-calendar>
      </div>
    </form>
    <div class="mt-4">
      <h1 class="m-0 text-900 text-3xl font-medium line-height-3">
        {{ t('voucher') }}
      </h1>
      <span>{{ t('voucher-Desc') }}</span>
      <form [formGroup]="voucherForm" class="flex flex-column flex-grow-1 mt-4">
        <div class="flex-auto p-fluid">
          <div class="mb-4">
            <label for="voucherTitle" class="block font-medium text-900 mb-2"
              >{{ t('voucherChallenge') }} [{{ t('lang-de') }}] *</label
            >
            <input
              id="voucherTitle"
              type="text"
              pInputText
              [pTooltip]="t('desc-prize-challenge-title')"
              tooltipPosition="top"
              formControlName="prizeTitle"
              [maxlength]="70"
            />
          </div>
          <div class="mb-4">
            <label for="voucherTitleEn" class="block font-medium text-900 mb-2"
              >{{ t('voucherChallenge') }} [{{ t('lang-en') }}]</label
            >
            <input
              id="voucherTitleEn"
              type="text"
              pInputText
              [pTooltip]="t('desc-prize-challenge-title-en')"
              tooltipPosition="top"
              formControlName="prizeTitleEn"
              [maxlength]="70"
            />
          </div>
        </div>
      </form>
      <div class="flex align-items-center gap-4 w-full" id="voucherImg">
        <img
          *ngIf="voucherForm?.get('imageUrl')?.value"
          height="100px"
          [src]="voucherForm?.get('imageUrl')?.value"
        />
        <p-button
          label="{{ t('chooseImage') }} *"
          icon="pi pi-file-import"
          [pTooltip]="t('voucherImage-tooltip')"
          tooltipPosition="top"
          [disabled]="!isScheduled"
          (click)="voucherInput.value = null; voucherInput.click()"
        ></p-button>
        <input
          #voucherInput
          id="voucherImage"
          type="file"
          accept="image/*"
          class="hidden"
          (change)="voucherFileChangeEvent($event)"
        />
        <image-cropper
          [imageChangedEvent]="voucherImageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="16 / 9"
          format="png"
          (imageCropped)="voucherImageCropped($event)"
        ></image-cropper>
      </div>
    </div>
    <div class="flex flex-column mt-4 gap-2">
      <div class="flex gap-4">
        <button
          [disabled]="!challengeForm.valid || voucherForm.invalid"
          pButton
          pRipple
          [loading]="voucherSaving || saving"
          loadingIcon="pi pi-spin pi-spinner"
          label="{{ t('saveChallenge') }}"
          class="w-auto"
          (click)="saveChallenge()"
          loadingIcon="pi pi-spin pi-spinner"
          iconPos="right"
        ></button>
        <p-button
          pRipple
          label="{{ t('cancel') }}"
          class="w-auto"
          styleClass="p-button-danger"
          [routerLink]="['/workspace', workspaceId, 'challenges']"
        ></p-button>
      </div>
    </div>
  </div>
</ng-container>
