import { Component } from '@angular/core';

@Component({
  selector: 'sweetpopcorn-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss'],
})
export class ImpressumComponent {
  openSettings() {
    (window as any).cookieconsent.openPreferencesCenter();
  }
}
