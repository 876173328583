import { Injectable } from '@angular/core';
import { WorkspaceDto, WorkspacesService } from '@sweetpopcorn/api-connector';
import { Observable, Subject, map, repeatWhen, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  private refreshWorkspacesSubject = new Subject<void>();

  constructor(private workspaceService: WorkspacesService) {}

  workspaces$: Observable<WorkspaceDto[]> = this.workspaceService
    .getAllWorkspaces(1, 100)
    .pipe(
      map((w) => w.items),
      repeatWhen(() => this.refreshWorkspacesSubject),
      shareReplay(1)
    );

  reloadWorkspaces() {
    this.refreshWorkspacesSubject.next();
  }
}
