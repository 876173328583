import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationGuard } from '@sweetpopcorn/common';
import { CreateWorkspaceComponent } from './create-workspace/create-workspace.component';
import { FaqComponent } from './faq/faq.component';
import { GtcComponent } from './gtc/gtc.component';
import { HelpComponent } from './help/help.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { UserBillingComponent } from './user/user-billing/user-billing.component';
import { UserContainerComponent } from './user/user-container/user-container.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { ChallengeDetailComponent } from './workspace-detail/challenge/challenge-detail/challenge-detail.component';
import { ChallengeFormComponent } from './workspace-detail/challenge/challenge-form/challenge-form.component';
import { ChallengeOverviewComponent } from './workspace-detail/challenge/challenge-overview/challenge-overview.component';
import { LinksComponent } from './workspace-detail/links/links.component';
import { SubmissionDetailComponent } from './workspace-detail/submission/submission-detail/submission-detail.component';
import { SubmissionOverviewComponent } from './workspace-detail/submission/submission-overview/submission-overview.component';
import { WorkspaceDetailComponent } from './workspace-detail/workspace-detail.component';
import { WorkspaceFormComponent } from './workspace-detail/workspace-form/workspace-form.component';
import { WorkspaceOverviewComponent } from './workspace-detail/workspace-overview/workspace-overview.component';
import { WorkspaceUserListComponent } from './workspace-detail/workspace-user-list/workspace-user-list.component';
import { WorkspacesOverviewComponent } from './workspaces-overview/workspaces-overview.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'workspaces',
  },
  {
    path: 'help',
    component: HelpComponent,
  },
  {
    path: 'impressum',
    component: ImpressumComponent,
  },
  {
    path: 'gtc',
    component: GtcComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'workspaces',
    pathMatch: 'full',
    canActivate: [AuthorizationGuard],
    component: WorkspacesOverviewComponent,
  },
  {
    path: 'create-workspace',
    pathMatch: 'full',
    canActivate: [AuthorizationGuard],
    component: CreateWorkspaceComponent,
  },
  {
    path: 'user',
    component: UserContainerComponent,
    canActivate: [AuthorizationGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'profile',
      },
      {
        path: 'profile',
        component: UserProfileComponent,
      },
      {
        path: 'billing',
        component: UserBillingComponent,
      },
    ],
  },
  {
    path: 'workspace/:workspaceId',
    component: WorkspaceDetailComponent,
    canActivate: [AuthorizationGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'uploads',
      },
      {
        path: 'uploads',
        component: WorkspaceOverviewComponent,
      },
      {
        path: 'links',
        component: LinksComponent,
      },
      {
        path: 'submissions',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: SubmissionOverviewComponent,
          },
          {
            path: ':submissionId',
            pathMatch: 'full',
            component: SubmissionDetailComponent,
          },
        ],
      },
      {
        path: 'users',
        component: WorkspaceUserListComponent,
      },
      {
        path: 'challenges',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ChallengeOverviewComponent,
          },
          { path: 'new', pathMatch: 'full', component: ChallengeFormComponent },
          {
            path: ':challengeId',
            pathMatch: 'full',
            component: ChallengeDetailComponent,
          },
          {
            path: ':challengeId/edit',
            pathMatch: 'full',
            component: ChallengeFormComponent,
          },
        ],
      },
      {
        path: 'settings',
        component: WorkspaceFormComponent,
      },
      {
        path: '**',
        redirectTo: 'overview',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'workspace',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
