<ng-container *transloco="let t">
  <p-confirmDialog
    #cd
    [header]="t('confirmation')"
    icon="pi pi-exclamation-triangle"
  >
    <ng-template pTemplate="footer">
      <button
        type="button"
        pButton
        icon="pi pi-times"
        label="{{ t('btn-no') }}"
        (click)="cd.reject()"
      ></button>
      <button
        type="button"
        pButton
        icon="pi pi-check"
        label="{{ t('btn-yes') }}"
        (click)="cd.accept()"
      ></button>
    </ng-template>
  </p-confirmDialog>
  <div
    class="flex flex-row px-0 py-4 md:px-4 surface-card shadow-2 border-round mt-4"
    *ngIf="submission$ | async as submission"
  >
    <div class="flex flex-column relative w-8 gap-4 ml-3">
      <div class="flex flex-column gap-2">
        <p-tag
          *ngIf="submission.isWinningSubmission"
          value="{{ t('winner') | uppercase }}"
        ></p-tag>
        <div class="flex flex-column">
          <label for="name">{{ t('name') }}</label>
          <div id="name" class="text-900 text-3xl font-medium mb-3">
            {{ submission?.firstName }} {{ submission?.lastName }}
          </div>
        </div>

        <div class="flex flex-column">
          <label for="dateOfBirth">{{ t('birthdate-label') }}</label>
          <div id="dateOfBirth" class="text-900 text-2xl font-medium mb-3">
            {{
              submission?.dateOfBirth | dfnsParseIso | dfnsFormat : 'dd.MM.yyyy'
            }}
          </div>
        </div>

        <div class="flex flex-column">
          <label for="email">Email</label>
          <a id="email" [href]="'mailto:' + submission?.email" class="">{{
            submission?.email
          }}</a>
        </div>

        <div class="flex flex-column">
          <label for="createdOn">{{ t('createdOn') }}</label>
          <p id="createdOn" class="mt-0 mb-3 text-700 text-xl">
            {{
              submission?.createdOn | dfnsParseIso | dfnsFormat : 'dd.MM.yyyy'
            }}
          </p>
        </div>

        <div class="text-600 font-medium">
          <span
            >{{ submission?.itemCount }} {{ t('items') }} |
            {{
              submission?.createdOn
                | dfnsParseIso
                | dfnsFormat : 'dd.MM.yyyy HH:mm'
            }}</span
          >
        </div>
      </div>
      <div class="flex flex-row gap-4 mt-3 lg:mt-0">
        <p-button
          *ngIf="submission.challengeId"
          label="{{ t('challenge') }}"
          icon="pi pi-calendar"
          [routerLink]="['./../../challenges', submission.challengeId]"
        ></p-button>
        <p-button
          *ngIf="userRole < 3"
          label="{{ t('deleteSubmission') }}"
          icon="pi pi-trash"
          styleClass="p-button-danger"
          (click)="deleteSubmission(submission)"
        ></p-button>
      </div>
    </div>
    <div class="flex flex-column ml-2">
      <div class="text-900 text-3xl font-medium mb-3">
        {{ t('contract-title') }}
      </div>
      <label for="contractDate">{{ t('contract-date-label') }}</label>
      <div id="contractDate" class="text-900 text-2xl font-medium mb-3">
        {{
          submission?.consentGivenOn | dfnsParseIso | dfnsFormat : 'dd.MM.yyyy'
        }}
      </div>
      <p-button
        label="{{ t('contract-btn') }}"
        icon="pi pi-download"
        (click)="downloadContract()"
      ></p-button>
    </div>
  </div>

  <div class="px-0 py-4 md:px-4 surface-card shadow-2 border-round mt-4">
    <p-dataView
      [value]="submissionItems$ | async"
      [paginator]="true"
      [lazy]="true"
      [rows]="nextPageSize"
      filterBy="name"
      layout="grid"
      (onLazyLoad)="loadData($event)"
      [totalRecords]="totalCount"
    >
      <ng-template pTemplate="header">
        <div class="flex flex-column md:flex-row gap-2">
          <div class="flex justify-content-center align-items-center mr-2">
            {{ t('filter-label') }}:
          </div>
          <p-toggleButton
            [(ngModel)]="checked"
            onLabel="=="
            offLabel=">="
            (onChange)="changeSort()"
          ></p-toggleButton>
          <p-dropdown
            [options]="sortOptions"
            [(ngModel)]="filterValue"
            [placeholder]="starPlaceholder"
            (onChange)="onSortChange($event)"
            styleClass="mb-2 md:mb-0"
          ></p-dropdown>
        </div>
      </ng-template>
      <ng-template let-item pTemplate="gridItem">
        <div class="col-12 sm:col-6 lg:col-3 xl:col-3">
          <div
            class="m-1 p-1 border-1 surface-border surface-card border-round"
          >
            <div class="flex flex-column align-items-center overflow-hidden">
              <sweetpopcorn-video
                class="mb-1"
                *ngIf="item?.contentType.includes('video')"
                [backgroundImageSource]="item?.thumbnail?.url"
                [videoSrc]="item?.file?.url"
              ></sweetpopcorn-video>

              <p-image
                *ngIf="item?.contentType.includes('image')"
                [previewImageSrc]="item?.file?.url"
                [imageStyle]="{
                  'max-width': '250px',
                  'max-height': '250px',
                  'object-fit': 'cover'
                }"
                [src]="item?.thumbnail?.url"
                alt="Image"
                width="250"
                height="250"
                [preview]="true"
              ></p-image>
            </div>
            <div class="flex align-items-center justify-content-around p-2">
              <p-rating
                [(ngModel)]="item.rating"
                (ngModelChange)="ratingChanged(item, $event)"
              ></p-rating>
              <button
                pButton
                icon="pi pi-cloud-download"
                class="p-button-link p-0"
                (click)="downloadImage(item)"
              ></button>
              <button
                *ngIf="userRole < 3"
                pButton
                type="button"
                icon="pi pi-trash"
                class="p-button-danger p-0"
                (click)="deleteConfirmation(item)"
              ></button>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </div>
</ng-container>
