import { Component, OnDestroy } from '@angular/core';
import { VideoOverlayComponent } from '@sweetpopcorn/ui';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'sweetpopcorn-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnDestroy {
  ref: DynamicDialogRef | undefined;

  constructor(public dialogService: DialogService) {}

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  show(url) {
    this.ref = this.dialogService.open(VideoOverlayComponent, {
      header: 'Helpful Video Title name',
      width: '70%',
      data: {
        videoUrl: url,
      },
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
    });
  }
}
